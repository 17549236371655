import React, { useState, useEffect, useRef } from 'react';
// mui
import { Box, Tabs, Tab, Collapse } from '@mui/material';
// apex charts
import Chart from 'react-apexcharts';
// zach
import { SX } from './utils/sx_styling';

// -------------------------------------------------------------------------------------------------------

export default function RawPpgPlot({ raw_ppg_data }) {
  /** component to generate apexchart of raw data (luminance, and R+B chroma) */

  let [chart_type, set_chart_type] = useState('luminance');

  let chart_ref = useRef();

  // auto-zoom to window (apexcharts seemingly only supports programatic x zooming)
  useEffect(() => {
    if (chart_ref.current) {
      let chart_data = get_chart_data();
      if (chart_data.good_windows.length === 0) {
        return;
      }
      let x_vals = [];
      for (let window of chart_data.good_windows) {
        x_vals.push(window.x);
        x_vals.push(window.x2);
      }
      let left = Math.min(...x_vals);
      let right = Math.max(...x_vals);
      let h_margin = (right - left) * 0.05;
      left -= h_margin;
      right += h_margin;
      chart_ref.current.chart.zoomX(left, right);
    }
  });

  function get_chart_data() {

    // handle missing or empty videoFrames
    if (raw_ppg_data.videoFrames === undefined || raw_ppg_data.videoFrames.length === 0) {
      let chart_data = {
        luminance_series: [],
        red_series: [],
        blue_series: [],
        good_windows: [],
      };
      return chart_data;
    }

    let data_to_plot = raw_ppg_data.videoFrames.map((item) => {
      let timestamp = item.frame.timestampInNanoSeconds;
      let luminance = item.frame.luminanceIntensity;
      let red = item.frame.chromaRedIntensity;
      let blue = item.frame.chromaBlueIntensity;
      return [timestamp, luminance, red, blue];
    });

    // make timestamp relative to initial time and convert to seconds
    let relative_time = data_to_plot[0][0];
    data_to_plot = data_to_plot.map((item) => {
      let time_s = (item[0] - relative_time) * 1e-9;
      return [time_s, ...item.slice(1)];
    });

    // format series for Chart
    let luminance_series = data_to_plot.map((item) => [item[0], item[1]]);
    let red_series = data_to_plot.map((item) => [item[0], item[2]]);
    let blue_series = data_to_plot.map((item) => [item[0], item[3]]);

    // get 'good' windows
    let good_windows = [];
    raw_ppg_data.bpEstimationResponses.forEach((response, idx_resp) => {
      if (response.estimate !== undefined && Object.keys(response.errors).length === 0) {
        Object.keys(response.estimate.timestamps).forEach((window_key, idx_win) => {
          let start = (response.estimate.timestamps[window_key].start - relative_time) * 1e-9;
          let end = (response.estimate.timestamps[window_key].end - relative_time) * 1e-9;
          good_windows.push({
            x: start,
            x2: end,
            borderColor: '#000',
            fillColor: 'rgb(0, 255, 0)',
          });
        });
      }
    });

    let chart_data = {
      luminance_series: luminance_series,
      red_series: red_series,
      blue_series: blue_series,
      good_windows: good_windows,
    };

    return chart_data;
  }

  function generate_raw_data_plot() {
    // [[timestamp (ns), luminance, red, blue], ... ]

    let chart_data = get_chart_data();

    // generate luminance Chart
    let raw_data_plot_luminance = (
      <Chart
        ref={chart_ref}
        options={{
          chart: {
            id: 'ppg_plot',
            width: '100%',
            type: 'line',
            zoom: {
              type: 'xy',
            },
            animations: {
              enabled: false,
            },
            // events: {
            //   click: (e, ctx, options) => {
            //     console.log(e, ctx, options);
            //     console.log(e.target)
            //   }
            // },
          },
          annotations: {
            xaxis: chart_data.good_windows,
          },
          xaxis: {
            type: 'numeric',
            title: {
              text: 'Time (s)',
            },
          },
          yaxis: {
            decimalsInFloat: 0,
            title: {
              text: 'Intensity',
            },
          },
          colors: ['rgb(0, 0, 0)', 'rgb(255, 0, 0)', 'rgb(0, 0, 255)'],
        }}
        series={[
          {
            name: 'Luminance',
            data: chart_data.luminance_series,
          },
        ]}
        type="line"
      />
    );
    let raw_data_plot_chroma = (
      <Chart
        options={{
          chart: {
            id: 'ppg_plot',
            width: '100%',
            type: 'line',
            zoom: {
              type: 'xy',
            },
            animations: {
              enabled: false,
            },
          },
          annotations: {
            xaxis: chart_data.good_windows,
          },
          xaxis: {
            type: 'numeric',
            title: {
              text: 'Time (s)',
            },
          },
          yaxis: {
            decimalsInFloat: 0,
            title: {
              text: 'Intensity',
            },
          },
          colors: ['rgb(255, 0, 0)', 'rgb(0, 0, 255)'],
        }}
        series={[
          {
            name: 'Chroma Red',
            data: chart_data.red_series,
          },
          {
            name: 'Chroma Blue',
            data: chart_data.blue_series,
          },
        ]}
        type="line"
      />
    );

    // tab between plots
    let tabs = (
      <Tabs
        sx={SX({ height: '7vh' })}
        value={chart_type}
        onChange={(event, value) => {
          set_chart_type(value);
        }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab value="luminance" label="Luminance" />
        <Tab value="chroma" label="Chroma" />
      </Tabs>
    );

    let tab_display = (
      <Box>
        <Collapse in={chart_type === 'luminance'}>{raw_data_plot_luminance}</Collapse>
        <Collapse in={chart_type === 'chroma'}>{raw_data_plot_chroma}</Collapse>
      </Box>
    );

    // return chart
    return (
      <Box sx={SX({ width: 1.0 })}>
        {tabs}
        {tab_display}
      </Box>
    );
  }

  if (!raw_ppg_data) {
    return 'No Raw PPG Data';
  }

  let ppg_plot = generate_raw_data_plot();

  return <Box sx={SX('m10', 'sub_section', 'border1')}>{ppg_plot}</Box>;
}
