import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
// mui
import { Box, Button } from '@mui/material';
// zach
import { useUserDataHook, useMeasurementDataHook } from './utils/hooks';
import {
  download_json_button,
  loading_view,
  process_auto_string,
  invalid_auto_string,
  get_past_user_selections,
  save_past_user_selection,
} from './utils/generic_utils';
import { NavigatingAutoStringContext } from './BloodPressureDashboard';
import UserDataGrid from './UserDataGrid';
import { ALLOW_IDENTIFYING_INFO } from './utils/settings';
import { SX } from './utils/sx_styling';

// -------------------------------------------------------------------------------------------------------

export default function UserGrid({
  request_user, // request_user: function to be called when a user's row is clicked
  users_selected, // users_selected state from bloodPressureDashboard to highlight already-open users
  auto_string, // string to automatically open measurment
  show_identifying_info, // bool; if true shows identifying info (user name)
  set_show_identifying_info, // setter for show_identifying_info
  set_title_text, // ContentCard
  set_status_message, // ContentCard
  set_busy, // ContentCard
  set_expand, // ContentCard
}) {
  /** UserGrid lists all users found from the call to useUserDataHook() in
   * a Card containing a grid. When a user's row is selected then the
   * request_user() function is called to make BloodPressureDashboard include
   * the user's UserCard in the render.
   */

  // chronology date range to properly query useMeasurementDataHook
  let [chronology_date_range, set_chronology_date_range] = useState([new Date(0), new Date(0)]);
  // user_date_map: object to store unique user ids and their latest measurement datetimes
  let [user_date_map, set_user_date_map] = useState({});
  // state is true when an auto_string is initially provided; allows auto navigation to specified measurement
  let [auto_string_execution, set_auto_string_execution] = useState(!!auto_string);
  // keeps track of past user selections to populate recents_user_data_grid
  let [past_user_selection_array, set_past_user_selection_array] = useState([]);

  // get setter to close navigating drawer
  let set_auto_string_loading_drawer_open = useContext(NavigatingAutoStringContext);

  // user_data: array of objects; one for each user
  let { user_data, mutate_user_data } = useUserDataHook();

  // // measurement_data: queried to get the order of users
  let { measurement_data } = useMeasurementDataHook({
    start_date: chronology_date_range[0].toISOString(),
    end_date: chronology_date_range[1].toISOString(),
  });

  // set_user_date_map when measurement_data is present or updated so that user may be sorted properly
  useEffect(() => {
    console.log('measurement_data', measurement_data);
    if (measurement_data) {
      let date_map = {};
      for (let measurement of measurement_data.reverse()) {
        if (date_map[measurement.userId] !== undefined) {
          continue;
        }
        date_map[measurement.userId] = measurement.created.at.date_object;
      }
      set_user_date_map(date_map);
    }
  }, [measurement_data]);

  // initial startup
  useEffect(() => {
    set_title_text('USERS');
    refresh_chronology();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle user data loading
  useEffect(() => {
    refresh_past_user_selection_array(); // run refresh when user data is updated
    if (!user_data) {
      set_status_message('Loading users...');
      set_busy(true);
      set_expand(false);
    } else {
      set_status_message('Finished loading users.');
      set_busy(false);
      set_expand(!auto_string_execution);
      console.log('user_data loaded:', user_data);
      // auto_string
      if (auto_string_execution) {
        try {
          let provided_individual_id = process_auto_string(auto_string).individual_id;
          let selection = user_data.find((item) => item.id === provided_individual_id);
          if (!selection) {
            throw new Error(`Could not find ${provided_individual_id}`);
          }
          let user_id = selection.id;
          request_user(user_id, get_user_name(user_id), auto_string);
        } catch (error) {
          invalid_auto_string(auto_string, error);
          set_auto_string_loading_drawer_open(false);
        } finally {
          set_auto_string_execution(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_data]);

  function refresh_past_user_selection_array() {
    /** refresh the users recently selected to populate recents_user_data_grid */
    if (!user_data) {
      return;
    }
    let new_past_user_selection_array = get_past_user_selections().map((selected_user_id) => {
      return user_data.find((user) => user.id === selected_user_id);
    });
    new_past_user_selection_array = new_past_user_selection_array.filter((item) => item !== undefined);
    set_past_user_selection_array(new_past_user_selection_array);
  }

  function refresh_chronology() {
    /** set date range for querying measurements to order users; currently hard-coded to last 7 days */

    let date = new Date();
    set_chronology_date_range([new Date(date - 7 * 86400000), date]); // last 7 days
  }

  function select_user(user_id) {
    /** callback when a user's row is clicked (to create UserCard) */

    // save in localStorage memory
    save_past_user_selection(user_id);

    // creates a UserCard in BloodPressureRequestor
    request_user(user_id, get_user_name(user_id));

    // collapse UserGrid
    set_expand(false);
  }

  function get_user_name(user_id) {
    let found = user_data.find((item) => item.id === user_id);
    if (!found) {
      return '???';
    }
    let name = found.givenName + ' ' + found.familyName;
    return name;
  }

  if (!user_data) {
    return loading_view('Loading Users...');
  }

  // make user info button if flag allows it
  let show_user_info_button = null;
  if (ALLOW_IDENTIFYING_INFO) {
    let user_info_button_text = show_identifying_info ? 'HIDE USER INFO' : 'SHOW USER INFO';
    let cond_style = show_identifying_info ? ['color_red', 'border1_red'] : [];
    show_user_info_button = (
      <Button
        sx={SX('button', 'width20', 'm10', { mb: 0, ml: 0 }, ...cond_style)}
        onClick={() => set_show_identifying_info(!show_identifying_info)}
      >
        {user_info_button_text}
      </Button>
    );
  }
  // refresh button
  let refresh_button = (
    <Button
      sx={SX('button', 'm10', { mb: 0 })}
      onClick={() => {
        mutate_user_data();
        refresh_chronology();
      }}
    >
      REFRESH
    </Button>
  );
  // utilities
  let user_grid_utilities = (
    <Box sx={SX('flex', 'right')}>
      {refresh_button}
      {show_user_info_button}
    </Box>
  );

  // modify users with chronology data
  user_data.forEach((item) => {
    item.date_of_last_measurement = user_date_map[item.id];
  });
  // sort user_data with chronology data
  user_data.sort((item1, item2) => {
    let crit1 = item1.date_of_last_measurement ? +item1.date_of_last_measurement : 0;
    let crit2 = item2.date_of_last_measurement ? +item2.date_of_last_measurement : 0;
    return crit2 - crit1;
  });

  // grid with recent users selected from localStorage
  let recents_user_data_grid = (
    <UserDataGrid
      user_data={past_user_selection_array}
      select_user={select_user}
      users_selected={users_selected}
      show_identifying_info={show_identifying_info}
      past_user_selections_flag={true}
      refresh_past_user_selection_array={refresh_past_user_selection_array}
    />
  );

  // grid with all users
  let user_data_grid = (
    <UserDataGrid
      user_data={user_data}
      select_user={select_user}
      users_selected={users_selected}
      show_identifying_info={show_identifying_info}
      refresh_past_user_selection_array={refresh_past_user_selection_array}
      search_bar={true}
    />
  );

  // return content
  let content = (
    <Box sx={SX('flex', 'column')}>
      {user_grid_utilities}
      {recents_user_data_grid}
      {user_data_grid}
      {download_json_button(user_data, 'user_data', 'DOWNLOAD', ['button', 'm3'])}
    </Box>
  );
  return content;
}

UserGrid.propTypes = {
  request_user: PropTypes.func.isRequired,
};
