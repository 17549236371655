import React from 'react';
// mui
import { Box, Drawer, Button, FormGroup, FormControlLabel, Checkbox, IconButton } from '@mui/material';
// zach
import { SX } from './utils/sx_styling';

// -------------------------------------------------------------------------------------------------------

export default function TagsFilterDrawer({
  tags_filter_drawer_open,
  set_tags_filter_drawer_open,
  measurement_data,
  tags_filter_array,
  set_tags_filter_array,
}) {
  /** provides drawer to list all tags flags found in measurement_data
   * and allow the user to specify filtering with checkboxes
   */

  // get array of checked tags
  let checked_tags = [];
  if (tags_filter_array !== null) {
    tags_filter_array.forEach((tag) => checked_tags.push(tag));
  }

  // get all tags
  let all_tags = measurement_data.reduce((p, c) => {
    Object.keys(c.tags).forEach((tag) => {
      p.add(tag);
    });
    return p;
  }, new Set());
  all_tags = [...all_tags];

  // close button
  let close_button = (
    <Box sx={SX('flex', 'right')}>
      <IconButton
        sx={SX('minimize_close_button', 'm10', { width: '5vw', height: '5vh' })}
        onClick={(event) => {
          set_tags_filter_drawer_open(false);
        }}
      >
        <Box component="img" src="/static/close_icon.svg" sx={SX('height100pct')} />
      </IconButton>
    </Box>
  );

  // tag filter activation button
  let filter_tags_toggle_button = (
    <Button
      sx={SX('m10', tags_filter_array === null ? 'toggle_off_button' : 'toggle_on_button')}
      onClick={() => {
        if (tags_filter_array === null) {
          set_tags_filter_array([]);
        } else {
          set_tags_filter_array(null);
        }
      }}
    >
      {tags_filter_array === null ? 'TAG FILTERING OFF' : 'TAG FILTERING ON'}
    </Button>
  );

  // checkboxes
  let checkboxes = [];
  for (let tag of all_tags) {
    let checkbox_with_label = (
      <FormControlLabel
        key={tag}
        control={
          <Checkbox
            checked={checked_tags.includes(tag)}
            disabled={tags_filter_array === null}
            onChange={() => {
              if (tags_filter_array.includes(tag)) {
                set_tags_filter_array(tags_filter_array.filter((item) => item !== tag));
              } else {
                set_tags_filter_array(tags_filter_array.concat(tag));
              }
            }}
          />
        }
        label={tag}
      />
    );
    checkboxes.push(checkbox_with_label);
  }

  // formgroup for checboxes
  let checkbox_content = <FormGroup sx={SX('m10')}>{checkboxes}</FormGroup>;

  return (
    <Drawer
      anchor={'left'}
      open={tags_filter_drawer_open}
      onClose={() => {
        set_tags_filter_drawer_open(false);
      }}
    >
      <Box sx={SX({ width: '50vw' }, 'flex', 'column', 'm10')}>
        {close_button}
        {filter_tags_toggle_button}
        {checkbox_content}
      </Box>
    </Drawer>
  );
}
