// mui
import { Box, Button } from '@mui/material';
// style
import { SX } from './sx_styling';

// -------------------------------------------------------------------------------------------------------

export function clean_measurement_data(measurement_data, comments_data, noEstimation_data, user_id) {
  /** add additional information to measurement_data */

  // add noEstimation data
  if (noEstimation_data && noEstimation_data.Code !== 'InternalServerError') {
    for (let noEst of noEstimation_data) {
      // get measurement data
      let split_key = noEst.s3_key.split('__');
      let measurement_id = split_key[split_key.length - 2];
      let date_object = new Date(noEst.datetime_int);
      let created = {
        at: {
          string: date_object.toISOString().slice(0, -5) + 'Z',
          date_object: date_object,
        },
      };
      // skip this noEstimation if it's already in measurement_data
      if (measurement_data.find((item) => item.id === measurement_id)) {
        continue;
      }
      // add
      measurement_data.push({
        created: created,
        type: 'NO_ESTIMATION',
        id: measurement_id,
        userId: user_id,
        comment: '',
        systolicBloodPressure: -1,
        diastolicBloodPressure: -1,
        noEstimation_s3_bucket: noEst.s3_bucket,
        noEstimation_s3_key: noEst.s3_key,
        s3Location: `s3://${noEst.s3_bucket}/${noEst.s3_key}`,
      });
    }
  }

  // get measurment ids omitted from calibration data
  let calibration_omissions = {
    cuffSbpOutlierIds: { ids: [], tag: 'cuffSbpOutlier' },
    cuffDbpOutlierIds: { ids: [], tag: 'cuffDbpOutlier' },
    cuffPpOutlierIds: { ids: [], tag: 'cuffPpOutlier' },
  };

  // loop through all measurements to modify as needed
  measurement_data = measurement_data.map((meas) => {
    // parse the creation date to include a Date object in each measurement
    let date_string = typeof meas.created.at == 'string' ? meas.created.at : meas.created.at.string;
    meas.created.at = {
      string: date_string,
      date_object: new Date(Date.parse(date_string)),
    };

    // adjust comments
    if (comments_data && comments_data.Code !== 'InternalServerError') {
      let comment = comments_data.find((item) => item.measurement_id === meas.id);
      if (comment) {
        meas.comment = comment.comment;
      }
    }
    if (!meas.comment) {
      meas.comment = '';
    }

    // flag to review based on comment
    meas.tags = {};

    // flag review in comment
    meas.tags.review_flag = meas.comment.toLowerCase().includes('review');

    // flag noEstimation
    if (meas.type === 'NO_ESTIMATION') {
      meas.tags.noEstimation_flag = true;
    }

    // flag calibration
    if (meas.calibrationReadingId !== '') {
      meas.tags.calibration_flag = true;
    }

    // flag as cuff omission if found in calibration data
    for (let key of Object.keys(calibration_omissions)) {
      if (calibration_omissions[key].ids.includes(meas.id)) {
        meas.tags[calibration_omissions[key].tag] = true;
      }
    }

    // flag measurement as an omission as necessary
    meas.bad_bp_value = is_bad_bp_measurement(meas);

    return meas;
  });

  // ensure measurements are ascending by date
  measurement_data.sort((first, second) => +first.created.at.date_object - +second.created.at.date_object);

  // calculate metrics
  measurement_data = calculate_measurement_MA_metrics(measurement_data);

  // classify anomalies
  measurement_data = classify_anomalies(measurement_data);

  // return
  return measurement_data;
}

export function is_bad_bp_measurement(measurement) {
  /** return true if the measurement should be omitted from calculations */

  // omit baseline and no estimation readings (there're no blood pressures in these)
  if (measurement.systolicBloodPressure <= 0 || measurement.diastolicBloodPressure <= 0) {
    return true;
  }

  // otherwise this is a good measurement
  return false;
}

export function calculate_measurement_MA_metrics(measurement_data) {
  /** add in 12pt moving avg and exponential weighted moving average (EWMA) */

  // reset metrics/flags
  measurement_data.forEach((meas) => {
    meas.systolic_ewma = null;
    meas.diastolic_ewma = null;
    meas.systolic_ma = null;
    meas.diastolic_ma = null;
    meas.systolic_std = null;
    meas.diastolic_std = null;
    meas.systolic_anomaly_threshold = null;
    meas.diastolic_anomaly_threshold = null;
    meas.tags.anomaly_threshold_exceeded = null;
  });

  // calulate and set metrics/flags
  measurement_data
    .filter((meas) => meas.bad_bp_value === false)
    .forEach((meas, idx, filtered_measurement_data) => {
      // exponentially weighted moving average
      let span = 12;
      let EWMA = function (value_history, span) {
        /** accepts a value history (ordered where the first element is the current measurement and the last element is the first measurement) */
        let alpha = 2 / (span + 1);
        let numerator = 0;
        let denominator = 0;
        value_history.forEach((value, n) => {
          numerator += value * (1 - alpha) ** n;
          denominator += (1 - alpha) ** n;
        });
        return numerator / denominator;
      };
      let syst_value_history = filtered_measurement_data
        .map((meas) => meas.systolicBloodPressure)
        .slice(0, idx + 1)
        .reverse();
      meas.systolic_ewma = EWMA(syst_value_history, span);
      let dias_value_history = filtered_measurement_data
        .map((meas) => meas.diastolicBloodPressure)
        .slice(0, idx + 1)
        .reverse();
      meas.diastolic_ewma = EWMA(dias_value_history, span);

      // 12pt moving average and std dev - window is shifted to the left
      let window_span = 12;
      let zero_end_range = (end, start) => [end < 0 ? 0 : end, start];
      // last 12 measurements including this one
      let latest_bps_inclusive_stats = calculate_bp_stats(
        filtered_measurement_data.slice(...zero_end_range(idx + 1 - window_span, idx + 1)).map((item) => {
          return [item.systolicBloodPressure, item.diastolicBloodPressure];
        })
      );
      // last 12 measurements NOT including this one
      let previous_bps_stats = calculate_bp_stats(
        filtered_measurement_data.slice(...zero_end_range(idx - window_span, idx)).map((item) => {
          return [item.systolicBloodPressure, item.diastolicBloodPressure];
        })
      );
      // set mean and std values to measurement
      meas.systolic_ma = latest_bps_inclusive_stats.mean[0];
      meas.diastolic_ma = latest_bps_inclusive_stats.mean[1];
      meas.systolic_std = latest_bps_inclusive_stats.std[0];
      meas.diastolic_std = latest_bps_inclusive_stats.std[1];

      // anomaly thresholding
      meas.systolic_anomaly_threshold = [
        previous_bps_stats.mean[0] - 3 * previous_bps_stats.std[0],
        previous_bps_stats.mean[0] + 3 * previous_bps_stats.std[0],
      ];
      meas.diastolic_anomaly_threshold = [
        previous_bps_stats.mean[1] - 3 * previous_bps_stats.std[1],
        previous_bps_stats.mean[1] + 3 * previous_bps_stats.std[1],
      ];

      // flag measurements if they exceed anomaly threshold
      meas.tags.anomaly_threshold_exceeded =
        meas.systolicBloodPressure < meas.systolic_anomaly_threshold[0] ||
        meas.systolicBloodPressure > meas.systolic_anomaly_threshold[1];
    });

  return measurement_data;
}

export function classify_anomalies(measurement_data) {
  /** anomaly detection algorithm modified from:
   * Peak Expiratory Flow Rate Control Chart in Asthma Care: Chart Construction and Use in Asthma Care
   * https://www.researchgate.net/publication/13397142_Peak_Expiratory_Flow_Rate_Control_Chart_in_Asthma_Care_Chart_Construction_and_Use_in_Asthma_Care
   */

  // filter measurmeent data to exclude bad_bp_value flag
  let filtered_measurement_data = measurement_data.filter((meas) => meas.bad_bp_value === false);

  // helper function to create array from range
  function range(start, end) {
    return Array(end - start)
      .fill()
      .map((_, idx) => start + idx);
  }

  // get mean and std
  let stats = calculate_bp_stats(
    filtered_measurement_data.map((item) => [item.systolicBloodPressure, item.diastolicBloodPressure])
  );
  stats = {
    systolicBloodPressure: { mean: stats.mean[0], std: stats.std[0] },
    diastolicBloodPressure: { mean: stats.mean[1], std: stats.std[1] },
  };

  // anomaly types (see label attribute for description)
  let anomaly_flags = [
    { key: 'anomaly_flag_3_sigma', sigma_thresh: 3, window: 1, criteria: 1, label: '1 pt exceeds 3 sigma' },
    { key: 'anomaly_flag_2_sigma', sigma_thresh: 2, window: 3, criteria: 2, label: '2 of 3 serial pts exceed 2 sigma' },
    // {'key': 'anomaly_flag_1_sigma', 'sigma_thresh': 1, 'window': 5, 'criteria': 4, 'label': '4 of 5 serial pts exceed 1 sigma'},
    // {'key': 'anomaly_flag_0_sigma', 'sigma_thresh': 0, 'window': 8, 'criteria': 8, 'label': '8 serial pts exceed mean'},
  ];

  // loop through both systolic and diastolic
  for (let bp_key of ['systolicBloodPressure', 'diastolicBloodPressure']) {
    let bp_data = filtered_measurement_data.map((item) => item[bp_key]);
    let mean = stats[bp_key].mean;
    let std = stats[bp_key].std;

    // loop through each type of anomaly mode
    for (let anomaly_mode of anomaly_flags) {
      // skip this mode if there aren't enough measurements to create a single window
      if (bp_data.length < anomaly_mode.window) {
        continue;
      }

      // iterate window through blood pressures
      let end_idxs = range(anomaly_mode.window, bp_data.length + 1);
      let start_idxs = end_idxs.map((item) => item - anomaly_mode.window);
      for (let idx of range(0, start_idxs.length)) {
        let start = start_idxs[idx];
        let end = end_idxs[idx];
        let window_data = bp_data.slice(start, end);

        // skip windows with data above and below the mean
        if (!(window_data.every((item) => item >= mean) || window_data.every((item) => item <= mean))) {
          continue;
        }

        // get points that exceed the threshold
        let exceeds = window_data.map((item) => Math.abs(item - mean) > anomaly_mode.sigma_thresh * std);

        // skip windows that don't meet the criteria of exceeds
        if (
          exceeds.reduce((p, c) => {
            return p + c;
          }, 0) < anomaly_mode.criteria
        ) {
          continue;
        }

        // add flags to tags object for measurements that exceed
        range(start, end).forEach((filtered_measurement_data_idx, window_data_idx) => {
          if (exceeds[window_data_idx]) {
            filtered_measurement_data[filtered_measurement_data_idx].tags[anomaly_mode.key + '_' + bp_key] = true;
          }
        });
      }
    }
  }

  return measurement_data;
}

export function riva_loading() {
  /** return Component with animated riva loading logo */

  return <Box component="img" src="/static/animated_logo.svg" />;
}

export function download_json_button(json_obj, filename = 'data', text = 'DOWNLOAD', sx = ['button']) {
  /** return a button that allows the user to download json_obj (a json object) upon clicking */

  return (
    <Button
      sx={SX(...sx, 'm10')}
      href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(json_obj))}`}
      download={`${filename}.json`}
      disabled={!json_obj}
    >
      {text}
    </Button>
  );
}

export function loading_view(message = 'Loading data...') {
  /** return a component with a loading message and the animated riva_loading icon */

  return (
    <Box sx={SX('flex', 'column', 'center', 'm10')}>
      <Box sx={SX('flex', 'center')}>{message}</Box>
      <br />
      <Box sx={SX('flex', 'center')}>{riva_loading()}</Box>
      <br />
    </Box>
  );
}

export function get_date_string(date_object) {
  /** convert date object to string for consistent display */

  let str = date_object.toString();
  return str.match('^(.*):.. GMT')[1];
}

export function gaussian(x, sigma) {
  /**  a normalized gaussian curve */
  return Math.exp(-(x ** 2) / (2 * sigma ** 2));
}

export function calculate_bp_stats(blood_pressures, round = false) {
  /** accepts and array of blood pressures [[systolic_bp, diastolic_bp], [systolic_bp, diastolic_bp], ...]
   * returns an object with blood pressure stats (average and std dev)
   * blood pressures with values less than 0 are omitted from calculations
   */

  // init
  let average_pressure = [0, 0];
  let std_pressure = [0, 0];

  if (blood_pressures && blood_pressures.length > 0) {
    // average of bp
    let mean = function (numbers) {
      /** accepts array of numbers to average */
      return numbers.reduce((prev, curr) => prev + curr, 0) / numbers.length;
    };
    average_pressure = [
      mean(blood_pressures.map((item) => item[0])), // systolic
      mean(blood_pressures.map((item) => item[1])), // diastolic
    ];

    // std dev of bp
    let std = function (numbers) {
      /** accepts array of numbers to get standard deviation of */
      let mean_value = mean(numbers);
      return mean(numbers.map((item) => (item - mean_value) ** 2)) ** (1 / 2);
    };
    std_pressure = [
      std(blood_pressures.map((item) => item[0])), // systolic
      std(blood_pressures.map((item) => item[1])), // diastolic
    ];

    if (round) {
      average_pressure = average_pressure.map((item) => Math.round(item));
      std_pressure = std_pressure.map((item) => Math.round(item));
    }
  }

  return {
    mean: average_pressure,
    std: std_pressure,
  };
}

export function process_auto_string(auto_string) {
  /** convert auto_string into an object by parsing */

  let individual_id, measurement_id;
  if (auto_string.includes('__')) {
    // both individual_id and measurement_id
    let split = auto_string.split('__');
    individual_id = split[0];
    measurement_id = split[1];
  } else {
    // just individual_id
    individual_id = auto_string;
    measurement_id = null;
  }
  return {
    individual_id: individual_id,
    measurement_id: measurement_id,
  };
}

export function invalid_auto_string(auto_string, extra) {
  /** create alert stating tags with the link provided */
  let message = `Invalid link: ${auto_string}` + (extra ? ` | ${extra}` : '');
  alert(message);
}

export function save_past_user_selection(user_id) {
  /** save a user to past_user_selections in localStorage */
  let selections = get_past_user_selections();
  selections = selections.filter((id) => id !== user_id);
  selections.unshift(user_id);
  while (selections.length > 6) {
    selections.pop();
  }
  set_past_user_selections(selections);
}

export function remove_past_user_selection(user_id) {
  /** remove a user from past_user_selections in localStorage */
  let selections = get_past_user_selections();
  selections = selections.filter((id) => id !== user_id);
  set_past_user_selections(selections);
}

export function get_past_user_selections() {
  /** get past_user_selections in localStorage */
  let str = localStorage.getItem('past_user_selections');
  if (str == null) {
    return [];
  }
  return str.split(',');
}

export function set_past_user_selections(arr) {
  /** set past_user_selections in localStorage */
  localStorage.setItem('past_user_selections', arr.join(','));
}
