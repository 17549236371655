import React, { useState, useRef } from 'react';

// mui
import { Box, Button, Collapse } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
// zach
import ContentCard from './ContentCard';
import { SX } from './utils/sx_styling';

// -------------------------------------------------------------------------------------------------------

export default function JupyterVisualizer() {
  /** Page that displays an undefined number of JupyterCards, each capable of visualizing a
   * jupyter nptebook
   */

  // count cards to create unique keys
  let JUPYTERCARDCOUNT = useRef(0);

  // array of indexes representing JupyterCard components
  let [jupyter_cards, set_jupyter_cards] = useState([]);

  function make_jupyter_card() {
    /** create a JupyterCard to be added to the display;
     * the key will seed the remove_jupyter_card function so that it can be closed
     */

    set_jupyter_cards(jupyter_cards.concat([JUPYTERCARDCOUNT.current++]));
  }

  function remove_jupyter_card(key) {
    /** remove a JupyterCard based on the key provided */

    set_jupyter_cards(
      jupyter_cards.filter((value) => {
        return value !== key;
      })
    );
  }

  // title
  let title = <Box sx={SX('width100', 'flex', 'center')}>Jupyter Visualization</Box>;

  // button to make a new JupyterCard
  let make_card_button = <Button onClick={() => make_jupyter_card()}>NEW CARD</Button>;

  // make jupyter cards
  let cards = [];
  jupyter_cards.forEach((item, idx) => {
    cards.push(
      <Collapse key={item}>
        <ContentCard card_type="JupyterCard" key={item} close_card={() => remove_jupyter_card(item)} />
      </Collapse>
    );
  });

  // return page
  return (
    <Box>
      {title}
      {make_card_button}
      <TransitionGroup>{cards}</TransitionGroup>
    </Box>
  );
}
