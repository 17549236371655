import React, { useRef, useEffect, useState, useContext } from 'react';
// d3
import * as d3 from 'd3';
// mui
import { Box } from '@mui/material';
// zach
import { LockViewContext } from './UserCard';
import { SX } from './utils/sx_styling';
import { draw_bp_line_chart } from './utils/draw_bp_chart/draw_bp_line_chart';
import { draw_bp_enumerate_chart } from './utils/draw_bp_chart/draw_bp_enumerate_chart';
import { draw_bp_timeline_chart } from './utils/draw_bp_chart/draw_bp_timeline_chart';
import { init_bp_markers, draw_bp_markers, calculate_scales } from './utils/draw_bp_chart/draw_bp_chart_utils';
// styles
import './styles/BpTimelineStyle.css';

// -------------------------------------------------------------------------------------------------------

export default function BpTimelineChart({
  measurement_data, // filtered measurement_data
  add_measurement_tab, // callback to create a tab after clicking a marker
  view_type, // string (timeline, enumerate, line) to indicate if the plot should ennumerate the bp sets (true) or show them on a date axis, or plot a line chart
}) {
  /** chart programmed in d3 to provide the blood pressure sets in the order that they were acquired */

  // lock views provided to indicate which measurements are shown on the locked view
  let lock_views = useContext(LockViewContext);

  // Bp Timeline Object (bto) to store BpTimelineChart values
  let bto = {};

  // ref for the svg (this is the plot/canvas/chart)
  bto.svg_ref = useRef(null);
  // ref for Box containing svg (to help handle chart sizings)
  let bp_timeline_container = useRef(null);
  // keeps track of canvas width and height [width (px), height (px)]
  let [canvas_dims, set_canvas_dims] = useState([100, 100]);
  // keep track of zoom for line chart
  let [zoom, set_zoom] = useState([]);
  // controls the state of the moving average trendlines (true: exponentially weighted; false: regular moving avg)
  let [ewma_state, set_ewma_state] = useState(true);

  // scales and dims
  bto.x_scale = useRef(null); // d3 linear scale function to convert percentage [0-100] to relative x position in pixels
  bto.y_scale = useRef(null); // d3 linear scale function to convert percentage [0-100] to relative y position in pixels
  bto.datetime_scale = useRef(null); // d3 linear scale function to convert date obj to x position for datetime axis
  bto.pressure_scale = useRef(null);
  // function to set canvas dims
  let calculate_canvas_dims = () => {
    set_canvas_dims([
      parseInt(bp_timeline_container.current ? bp_timeline_container.current.offsetWidth : 100),
      parseInt(bp_timeline_container.current ? bp_timeline_container.current.offsetHeight : 100),
    ]);
  };

  // settings
  bto.axis_level = 60; // determines datetime axis y position [0-100]
  bto.axis_font_size = 1; // datetime axis fontsize (in em)
  bto.marker_level = 46; // determines bp markers' y position [0-100]
  bto.marker_font_size = 0.5; // bp marker fontsize (in em while not expanded)
  bto.pressure_bottom_extent = 80; // bottom of pressure scale (%)
  bto.pressure_top_extent = 10; // top of pressure scale (%)
  bto.datetime_left_extent = 10;
  bto.datetime_right_extent = view_type === 'line' ? 85 : 90;

  // enumerated sizing for use in svg sizing and marker creation
  bto.enum_sizes = {
    radius: 20,
    separation: 10,
    pad: 25,
  };
  bto.enum_sizes.total_height =
    measurement_data.length * 2 * bto.enum_sizes.radius +
    bto.enum_sizes.separation * (measurement_data.length - 1) +
    2 * (bto.enum_sizes.pad - bto.enum_sizes.radius);

  // draw when data is updated
  useEffect(() => {
    draw();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measurement_data, canvas_dims, view_type, zoom, ewma_state]);

  // redraw on window resize
  useEffect(() => {
    let resize_observer = new ResizeObserver((entries) => {
      calculate_canvas_dims();
    });
    resize_observer.observe(bp_timeline_container.current);
    return () => {
      resize_observer.disconnect();
    };
  }, []);

  function draw() {
    /** core function; redraws the entire BpTimelineChart */

    // calculate and set scales to refs
    calculate_scales(bto, measurement_data, canvas_dims, zoom);

    // select svg to draw and set dimensions + color
    let svg = d3
      .select(bto.svg_ref.current)
      .attr('width', `${canvas_dims[0]}px`)
      .attr('height', `${canvas_dims[1]}px`)
      .style('background-color', 'white');

    // remove everything to redraw
    svg.selectAll('*').remove();
    svg.on('mousemove', null); // reset event handling
    svg.on('mouseleave', null); // reset event handling
    svg.style('cursor', null); // reset cursor

    // draw different types of charts
    if (view_type === 'line') {
      // line chart
      draw_bp_line_chart(bto, measurement_data, add_measurement_tab, ewma_state, zoom, set_zoom, set_ewma_state);
    } else {
      // timeline or enumerate
      init_bp_markers(bto, measurement_data, add_measurement_tab);
      if (view_type === 'enumerate') {
        // enumerate
        draw_bp_enumerate_chart(bto, measurement_data);
      } else {
        // timeline
        draw_bp_timeline_chart(bto, measurement_data);
      }
      draw_bp_markers(bto, lock_views);
    }
  }

  // return
  let svg_chart = <svg ref={bto.svg_ref} />;
  return (
    <Box
      sx={SX({ height: '40vh', width: 1.0, overflow: view_type !== 'line' ? 'auto' : 'none' })}
      ref={bp_timeline_container}
    >
      {svg_chart}
    </Box>
  );
}
