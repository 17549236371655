import React, { useState, useEffect } from 'react';
// mui
import { Box, Card, CardHeader, Collapse, Fade, IconButton } from '@mui/material';
// zach
import { riva_loading } from './utils/generic_utils';
import { SX } from './utils/sx_styling';
import UserCard from './UserCard';
import UserGrid from './UserGrid';
import JupyterCard from './JupyterCard';

// -------------------------------------------------------------------------------------------------------

export default function ContentCard({
  card_type = null, // indicates the type of card and what it contains
  close_card, // function to close this card; omits close button if not provided
  request_user, // required for UserGrid
  users_selected, // required for UserGrid
  user_id, // required for UserCard
  auto_string, // optional for UserGrid and UserCard
  show_identifying_info, // required for UserGrid and UserCard
  set_show_identifying_info, // required for UserGrid
  user_name, // subtitle for UserCard
}) {
  /** abstract component for cards to display content in an array */

  // title_text: string; title of ContentCard
  let [title_text, set_title_text] = useState('title');
  // status: string; message to be displayed in ContentCard's subheader
  let [status_message, set_status_message] = useState('...');
  // expand: bool; if true ContentCard expands, otherwise it collapses to just its header
  let [expand, set_expand] = useState(false);
  // busy: bool; flag used to indicate that content is being loaded
  let [busy, set_busy] = useState(false);
  // alive: bool; set to false when fading out to remove ContentCard after fading out
  let [alive, set_alive] = useState(true);
  // fade_in: bool; fades in when set to true and fades out when set to false
  let [fade_in, set_fade_in] = useState(false);
  // good_card: bool; set to false if to make header red and bad >:(
  let [good_card, set_good_card] = useState(true);

  // create object to pass state and setters to card_type components
  let content_card_state_and_setters = {
    title_text: title_text,
    set_title_text: set_title_text,
    status_message: status_message,
    set_status_message: set_status_message,
    expand: expand,
    set_expand: set_expand,
    busy: busy,
    set_busy: set_busy,
    alive: alive,
    set_alive: set_alive,
    fade_in: fade_in,
    set_fade_in: set_fade_in,
    good_card: good_card,
    set_good_card: set_good_card,
  };

  // fade the card in nicely when created
  useEffect(() => {
    set_fade_in(true);
  }, []);

  function close_self() {
    /** callback to close ContentCard AFTER Fading (see <Fade> transistion container) */

    if (typeof close_card != 'function') {
      console.log('close_card is not a function! Did you forget to pass it as a prop?');
    }
    set_fade_in(false);
    set_alive(false);
  }

  // make close button only if card is closable
  let close_button = close_card ? (
    <IconButton
      sx={SX('minimize_close_button', 'm3')}
      onClick={(event) => {
        event.stopPropagation();
        close_self();
      }}
    >
      <Box component="img" src="/static/close_icon.svg" sx={SX('height100pct')} />
    </IconButton>
  ) : null;

  // show loading icon when card is set to busy
  let loading_icon = busy ? riva_loading() : null;

  // create title with title_text, the loading icon, and the close button
  let title = (
    <Box sx={SX('flex', 'card_title', 'height60')}>
      <Box sx={SX('inline_flex', 'grow1')}>{title_text}</Box>
      <Box sx={SX('inline_flex')}>
        {loading_icon}
        {close_button}
      </Box>
    </Box>
  );

  // set header background based on good_card state
  let header_background = good_card ? 'riva_background_1' : 'riva_background_3';

  // get content of card based on type
  let content;
  if (card_type === 'UserGrid') {
    content = (
      <UserGrid
        request_user={request_user}
        users_selected={users_selected}
        auto_string={auto_string}
        show_identifying_info={show_identifying_info}
        set_show_identifying_info={set_show_identifying_info}
        {...content_card_state_and_setters}
      />
    );
  } else if (card_type === 'UserCard') {
    content = (
      <UserCard
        user_id={user_id}
        auto_string={auto_string}
        show_identifying_info={show_identifying_info}
        user_name={user_name}
        {...content_card_state_and_setters}
      />
    );
  } else if (card_type === 'JupyterCard') {
    content = <JupyterCard {...content_card_state_and_setters} />;
  } else {
    throw new Error(`unsupported card_type ${card_type}`);
  }

  // return ContentCard
  return (
    <Fade
      in={fade_in}
      onExited={() => {
        if (!alive) {
          close_card();
        }
      }}
    >
      <Card sx={SX('ContentCard')}>
        <CardHeader
          title={title}
          subheader={status_message}
          sx={SX(header_background, 'pointer')}
          onClick={() => set_expand(!expand)}
        ></CardHeader>
        <Collapse in={expand}>{content}</Collapse>
      </Card>
    </Fade>
  );
}
