import React, { useState, useEffect, useRef } from 'react';
import { Image } from 'react-native';
// mui
import { Box, Button, Slider } from '@mui/material';
// zach
import { SX } from './utils/sx_styling';

// -------------------------------------------------------------------------------------------------------

export default function ImageWindow({
  src, // url to image
  height = '50vh', // height of image window's viewer
}) {
  /** component to allow the display, zooming, and panning of an image (src) */

  // keep track of viewer_box dimensions [x (px), y (px)] to generate zoom properly
  let [viewer_dimensions, set_viewer_dimensions] = useState([100, 100]);
  // keep track of image display size [x (px), y (px)] to calculate zoom properly
  let [image_default_display_size, set_image_default_display_size] = useState([100, 100]);
  // keep track of zoom
  let [zoom, set_zoom] = useState(100);
  // track panning [bool isPanning, x location (px), y location (px)]
  let [panning, set_panning] = useState([false, 0, 0]);
  // stores translation from panning [x (px), y (px)]
  let [translation, set_translation] = useState([0, 0]);

  // used to get current size of image window space
  let viewer_box = useRef();

  // upon startup
  useEffect(() => {
    // listen for resizes
    window.addEventListener('resize', calculate_image_default_display_size);
    // setup view sizing
    calculate_image_default_display_size();
    // remove listener on unmounting
    return () => {
      window.removeEventListener('resize', calculate_image_default_display_size);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // recalculate size when src changes
  useEffect(() => {
    calculate_image_default_display_size();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  // ensure that defualt image size is set appropriately based on viewer_box
  function calculate_image_default_display_size() {
    Image.getSize(src, (image_width, image_height) => {
      let viewer_width = viewer_box.current ? viewer_box.current.clientWidth : 100;
      let viewer_height = viewer_box.current ? viewer_box.current.clientHeight : 100;
      // take the min to ensure that the 100% zoom image isn't too tall OR too wide
      let scale = Math.min(viewer_width / image_width, viewer_height / image_height);
      let new_image_default_display_size = [Math.round(image_width * scale), Math.round(image_height * scale)];
      set_image_default_display_size(new_image_default_display_size);
      set_viewer_dimensions([viewer_width, viewer_height]);
    });
  }

  // support image panning when clicking down on mouse (to start pan)
  function handle_mouse_down(event) {
    event.preventDefault();
    event.stopPropagation();
    set_panning([true, event.clientX, event.clientY]);
  }

  // support image panning when releasing mouse (to stop pan)
  function handle_mouse_up(event) {
    event.preventDefault();
    event.stopPropagation();
    set_panning([false, 0, 0]);
  }

  // support image panning when moving (as you pan)
  function handle_mouse_move(event) {
    event.preventDefault();
    event.stopPropagation();
    if (panning[0]) {
      let current_pan = [event.clientX - panning[1], event.clientY - panning[2]];
      set_translation([translation[0] + current_pan[0], translation[1] + current_pan[1]]);
      set_panning([true, event.clientX, event.clientY]);
    }
  }

  // support image panning (when mouse leaves image window)
  function handle_mouse_out(event) {
    event.preventDefault();
    event.stopPropagation();
    set_panning([false, 0, 0]);
  }

  // account for zoom (convert from arbitrary 0-100 scale to 0%-300%)
  function handle_zoom(event, new_zoom) {
    new_zoom = new_zoom * 6;
    if (new_zoom >= 0 && new_zoom <= 600) {
      set_zoom(new_zoom);
    }
  }

  // get bar for navigating image
  let nav_bar_zoom_display = (
    <Box sx={SX('grow1', 'flex', 'center', 'sub_section', 'm10')}>{`${Math.round(zoom)}%`}</Box>
  );
  let nav_bar_zoom_slider = (
    <Slider value={zoom / 6} onChange={(event, value) => handle_zoom(event, value)} sx={SX('grow3')} />
  );
  let nav_bar_reset_button = (
    <Button
      sx={SX('grow1', 'flex', 'center', 'button', 'm10')}
      onClick={() => {
        handle_zoom(null, 16.666666667);
        set_translation([0, 0]);
      }}
    >
      Reset
    </Button>
  );
  let navigation_bar_zoom_tools = (
    <Box sx={SX('center_items', 'flex', 'm3')}>
      {nav_bar_zoom_display}
      {nav_bar_zoom_slider}
      {nav_bar_reset_button}
    </Box>
  );
  let naviagation_bar = <Box sx={SX('sub_section', 'm10')}>{navigation_bar_zoom_tools}</Box>;

  // generate and format Image
  let main_image_width = Math.round((image_default_display_size[0] * zoom) / 100);
  let main_image_height = Math.round((image_default_display_size[1] * zoom) / 100);
  let main_image = (
    <Image
      source={{ uri: src }}
      style={SX({
        width: main_image_width,
        height: main_image_height,
        transform: `translate(${translation[0]}px, ${translation[1]}px)`,
      })}
    />
  );

  // include viewer with viewer_box ref to handle panning and contain Image
  let viewer = (
    <Box
      style={SX({ height: height, overflow: 'hidden' }, 'flex', 'center', 'center_items', 'curve_border')}
      ref={viewer_box}
      onMouseDown={(event) => handle_mouse_down(event)}
      onMouseUp={(event) => handle_mouse_up(event)}
      onMouseMove={(event) => handle_mouse_move(event)}
      onMouseOut={(event) => handle_mouse_out(event)}
    >
      <Box
        sx={SX('flex', 'center', 'center_items', {
          width: `${viewer_dimensions[0]}px`,
          height: `${viewer_dimensions[1]}px`,
          overflow: 'hidden',
        })}
      >
        {main_image}
      </Box>
    </Box>
  );

  // return nav bar and image
  let image_window = (
    <Box sx={SX('sub_section', 'm10')}>
      {viewer}
      {naviagation_bar}
    </Box>
  );
  return image_window;
}
