import React from 'react';
// lodash
import _ from 'lodash';
// mui
import { Paper, Box, Collapse, IconButton } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
// zach
import { get_date_string, remove_past_user_selection } from './utils/generic_utils';
import { SX } from './utils/sx_styling';

// -------------------------------------------------------------------------------------------------------

export default function UserDataGrid({
  user_data, // array of objects; one for each user
  select_user, // callback when a user's row is clicked (to create UserCard)
  users_selected, // users_selected state from bloodPressureDashboard to highlight already-open users
  show_identifying_info, // bool; if true shows identifying info (user name)
  past_user_selections_flag = false, // determines type of data grid; if true shows past users selected for this device
  refresh_past_user_selection_array, // callback to refresh the users recently selected to populate recents_user_data_grid
  search_bar, // toggle search bar for grid
}) {
  // map users_selected to just get an array of user_ids
  let selected_user_ids = users_selected.map((item) => item.user_id);

  // selections used to format UserDataGrid
  let SELECTIONS = [
    { field: 'id', keys: null, name: '', flex: [1, 1], show: true, sorter: null },
    { field: 'user_id', keys: 'id', name: 'User ID', flex: [4, 4], show: true, sorter: null },
    {
      field: 'name',
      keys: ['givenName', 'familyName'],
      name: 'Name',
      flex: [null, 2],
      show: show_identifying_info,
      sorter: null,
    },
    {
      field: 'last_measurement',
      keys: 'date_of_last_measurement',
      name: 'Last Measurement',
      flex: [4, 4],
      show: true,
      modify_value: (value) => {
        return value ? get_date_string(value) : '[unordered]';
      },
      sorter: (item1, item2) => {
        return Date.parse(item1) - Date.parse(item2);
      },
    },
  ];
  SELECTIONS = SELECTIONS.filter((item) => item.show);

  function generate_row_col(item, idx) {
    /** generates row/column data based on SELECTIONS
     * return row info with identifying info; if no item is provided then returns header data */

    if (item) {
      // row
      let row = {};
      SELECTIONS.forEach((selection) => {
        let value = selection.keys
          ? Array.isArray(selection.keys)
            ? selection.keys.map((key) => item[key]).join(' ')
            : item[selection.keys]
          : idx;
        if (selection.modify_value) {
          value = selection.modify_value(value);
        }
        row[selection.field] = value;
      });
      return row;
    } else {
      // columns
      let columns = [];
      SELECTIONS.forEach((selection) => {
        // column by selection data
        let col_def = {
          field: selection.field,
          headerName: selection.name,
          flex: selection.flex[show_identifying_info ? 1 : 0],
        };
        if (selection.sorter) {
          col_def.sortComparator = selection.sorter;
        }
        columns.push(col_def);
      });
      // for all columns
      columns = columns.map((item) => {
        return _.merge(item, {
          editable: false,
          headerClassName: 'col_head',
        });
      });
      // column for past user selections
      let close_row = function (event, params) {
        event.stopPropagation();
        remove_past_user_selection(params.row.user_id);
        refresh_past_user_selection_array();
      };
      if (past_user_selections_flag) {
        columns.push({
          editable: false,
          headerClassName: 'col_head',
          field: 'close',
          headerName: '',
          flex: 1,
          align: 'center',
          sortable: false,
          renderCell: (params) => {
            return (
              <IconButton
                onClick={(event) => {
                  close_row(event, params);
                }}
              >
                <Box component="img" src="/static/close_icon.svg" sx={SX({ width: '25px' })} />
              </IconButton>
            );
          },
        });
      }
      return columns;
    }
  }

  // create an array of rows, one for each user
  let grid_rows = [];
  let row_class = 'row_b';
  user_data.forEach((item, idx) => {
    // alternate rowClass for distinctly colored rows
    row_class = row_class === 'row_a' ? 'row_b' : 'row_a';
    grid_rows.push(generate_row_col(item, idx));
  });
  let grid_columns = generate_row_col();

  let user_data_grid = (
    <Collapse in={user_data.length !== 0}>
      <Box key={'grid_title'} sx={SX('flex', 'center', { marginTop: '25px' })}>
        {past_user_selections_flag ? 'RECENT USERS' : 'ALL USERS'}
      </Box>
      <Paper key={'grid'} sx={SX({ height: past_user_selections_flag ? '30vh' : '50vh' }, 'm10')} elevation={4}>
        <DataGrid
          sx={SX(
            past_user_selections_flag ? 'user_grid_data_grid_recents' : 'user_grid_data_grid_main',
            'user_grid_data_grid'
          )}
          rows={grid_rows}
          columns={grid_columns}
          disableSelectionOnClick
          onRowClick={(params, event) => {
            select_user(params.row.user_id);
            refresh_past_user_selection_array();
          }}
          getRowClassName={(params) => {
            return selected_user_ids.includes(params.row.user_id) ? 'highlight_row' : '';
          }}
          components={{ Toolbar: GridToolbar }}
          disableColumnFilter
          disableColumnSelector
          disableExportButton
          componentsProps={{
            toolbar: {
              showQuickFilter: search_bar ? true : false,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Paper>
    </Collapse>
  );

  return user_data_grid;
}
