import React, { useState } from 'react';
// auth0
import { useAuth0 } from '@auth0/auth0-react';
// routing
import { Link, useLocation } from 'react-router-dom';
// icons
import { Icon } from '@iconify/react';
// mui
import { Box, List, ListItemButton, ListItemIcon, ListItemText, Drawer } from '@mui/material';
// zach
import InfoDrawer from './InfoDrawer';
import { SX } from './utils/sx_styling';

// -------------------------------------------------------------------------------------------------------

export default function SideBar({ sidebar_width, set_sidebar_width }) {
  /** allows for user navigation though the different pages in Riva DIVA */

  // auth0
  let { logout } = useAuth0();

  // domain to return to (eg on logout)
  let domain = window.location.href.match('(.*)' + window.location.pathname)[1];

  // keep state to know if info drawer is open or not
  let [info_open, set_info_open] = useState(false);

  // bool to indicate that sidebar should be shrunk if it gets too small
  let sidebar_shrunk = sidebar_width < 15;

  // set width of divider
  let divider_width = 1;

  // sidebar content listed verbatim here
  let get_icon = (name) => <Box component={Icon} icon={name} sx={SX('SideBar_icon')} />;
  let sidebar_buttons = [
    {
      title: 'Dashboard',
      path: '/dashboard',
      icon: get_icon('eva:pie-chart-2-fill'),
    },
    {
      title: 'Jupyter Visualizer',
      path: '/jupytervisualizer',
      icon: get_icon('simple-icons:jupyter'),
    },
    {
      title: 'Testing Grounds',
      path: '/testinggrounds',
      icon: get_icon('file-icons:test-react'),
    },
    {
      title: 'Info',
      path: null,
      icon: get_icon('akar-icons:info'),
      onClick: () => {
        set_info_open(true);
      },
    },
    {
      title: 'Logout',
      path: null,
      icon: get_icon('bx:log-out'),
      onClick: () => {
        logout({ returnTo: domain });
      },
    },
  ];

  // get url location to highlight correct selection in sidebar_content
  let pathname = useLocation().pathname;
  // create list of buttons for navigation throughout Riva DIVA
  let sidebar_list = (
    <List>
      {sidebar_buttons.map((item, idx) => {
        let style = item.path === pathname ? ['selected_sidebar'] : [];
        style = style.concat([{ width: sidebar_width - divider_width + 'vw', height: '7vh' }]);

        // path
        let button_icon = <ListItemIcon>{item.icon}</ListItemIcon>;
        let button_text = <ListItemText primary={item.title} />;
        let list_item_button;
        // make all sidebar buttons link to their paths, except info button which opens its drawer agnostic to user location
        if (item.path) {
          list_item_button = (
            <ListItemButton component={Link} to={item.path} key={idx} sx={SX(...style)}>
              {button_icon}
              {sidebar_shrunk ? null : button_text}
            </ListItemButton>
          );
        } else {
          list_item_button = (
            <ListItemButton onClick={item.onClick} key={idx} sx={SX(...style)}>
              {button_icon}
              {sidebar_shrunk ? null : button_text}
            </ListItemButton>
          );
        }

        return list_item_button;
      })}
    </List>
  );

  // create divider
  // callbacks
  let divider_mousedown_callback = (event) => {
    window.addEventListener('mouseup', divider_mouseup_callback);
    window.addEventListener('mousemove', divider_mousemove_callback);
  };
  let divider_mouseup_callback = (event) => {
    window.removeEventListener('mouseup', divider_mouseup_callback);
    window.removeEventListener('mousemove', divider_mousemove_callback);
  };
  let divider_mousemove_callback = (event) => {
    let new_lock_split = (event.clientX / window.innerWidth) * 100;
    if (new_lock_split > 40) {
      new_lock_split = 40;
    }
    if (new_lock_split < 7) {
      new_lock_split = 7;
    }
    set_sidebar_width(new_lock_split);
  };
  // element
  let slide_divider = (
    <Box
      sx={SX('divider_border', { width: divider_width + 'vw', height: '100vh' })}
      onMouseDown={divider_mousedown_callback}
    />
  );

  // sidebar content (make invisible if sidebar is too small)
  let sidebar_content = (
    <Box sx={SX('flex', 'column', 'center_items')}>
      <Box sx={SX('width100', 'flex', 'center')}>
        <Box component="img" src="/static/logo.svg" sx={SX({ height: '10%' }, 'flex', 'm25')} />
      </Box>
      {sidebar_list}
    </Box>
  );

  // create sidebar (left-side Drawer)
  let sidebar = (
    <Drawer
      variant="persistent"
      anchor={'left'} // ['left', 'right', 'top', 'bottom']
      open={true}
    >
      <Box sx={SX('flex')}>
        <Box sx={SX({ width: sidebar_width - divider_width + 'vw' })}>{sidebar_content}</Box>
        {slide_divider}
      </Box>
    </Drawer>
  );

  // create info drawer
  let info_drawer = <InfoDrawer info_open={info_open} set_info_open={set_info_open} />;

  return (
    <Box>
      {sidebar}
      {info_drawer}
    </Box>
  );
}
