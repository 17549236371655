import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// mui
import { Box } from '@mui/material';
// zach
import { calculate_bp_stats } from './utils/generic_utils';
import { SX } from './utils/sx_styling';

// -------------------------------------------------------------------------------------------------------

export default function UserMetadataView({
  measurement_data, // array of objects; one for each measurement
}) {
  /** provides a chart of metadata gleaned from the provided measurment_data */

  // keeps track of measurements stats as measurement_data changes
  let [bp_stats, set_bp_stats] = useState(calculate_bp_stats(null, true));

  // get/update bp_stats
  useEffect(() => {
    let blood_pressures = measurement_data
      .filter((meas) => !meas.bad_bp_value)
      .map((item) => {
        return [item.systolicBloodPressure, item.diastolicBloodPressure];
      });
    set_bp_stats(calculate_bp_stats(blood_pressures, true));
  }, [measurement_data]);

  // content will report the metadata
  let content = [];

  // number of measurements
  content.push(
    <Box key={'num_meas'} sx={SX('row_a')}>
      {`Number of measurements: ${measurement_data.length}`}
    </Box>
  );

  // average bp
  content.push(
    <Box key={'avg_bp'} sx={SX('row_b')}>
      {`Average BP: ${bp_stats.mean[0]} / ${bp_stats.mean[1]} mmHg`}
    </Box>
  );

  // std dev bp
  content.push(
    <Box key={'med_bp'} sx={SX('row_a')}>
      {`Std Dev BP: ${bp_stats.std[0]} / ${bp_stats.std[1]} mmHg`}
    </Box>
  );

  // return in chart-like display
  return (
    <Box sx={SX('flex', 'column', 'grow1', 'sub_section', 'm10')}>
      <Box sx={SX('flex', 'center', 'm3')}>{'METADATA'}</Box>
      <Box sx={SX('m3')}>{content}</Box>
    </Box>
  );
}

UserMetadataView.propTypes = {
  measurement_data: PropTypes.array.isRequired, // array of objects; one for each measurement
};
