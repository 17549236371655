import React, { useEffect, useState, useContext } from 'react';
// mui
import { Box, Tabs, Tab, Button, Collapse } from '@mui/material';
// zach
import { get_date_string, process_auto_string, invalid_auto_string } from './utils/generic_utils';
import BpTimeline from './BpTimeline';
import MeasurementView from './MeasurementView';
import UserMetadataView from './UserMetadataView';
import { NavigatingAutoStringContext } from './BloodPressureDashboard';
import ShareDivaLinkBox from './ShareDivaLinkBox';
import { SX } from './utils/sx_styling';

// -------------------------------------------------------------------------------------------------------

export default function TabMeasurementsView({
  measurement_data, // array of objects; one for each measurement
  mutate_data, // method to refresh data loaded from hooks
  use_timeline, // set the tab view to be populated by a permamnent tab timeline or be pre-filled with measurement_data tabs
  lock_views, // views for locked view in UserCard
  set_lock_views, // used to set the locked view in the UserCard
  auto_string, // string to automatically open measurment
  user_id, // user_id of data
}) {
  /** Component providing a tabular view of the measurement timeline and selected measurements. Measurement tabs are MeasurementView elements,
   * usually added by the user interacting with the BpTimeline element contained in the 'timeline' default tab.
   */

  // height for tabs
  let tab_height = '7vh';

  // use state to only init tabs once
  let [init_tabs, set_init_tabs] = useState(true);
  // current_tab: object; keeps track of the UserCard's current tab
  let [current_tab, set_current_tab] = useState(null);
  // session_tabs: array of objects; keeps track of all currently existing tabs
  let [session_tabs, set_session_tabs] = useState([]);

  // state is true when an auto_string is initially provided; allows auto navigation to specified measurement
  let [auto_string_execution, set_auto_string_execution] = useState(!!auto_string);

  // get setter to close navigating drawer
  let set_auto_string_loading_drawer_open = useContext(NavigatingAutoStringContext);

  // update/reset tabs when measurement_data changes
  useEffect(() => {
    if (init_tabs) {
      let initial_tabs = use_timeline
        ? [{ id: 'timeline' }]
        : measurement_data.map((meas) => {
            return { id: meas.id };
          });
      set_session_tabs(initial_tabs);
      set_current_tab(initial_tabs[0]);
      set_init_tabs(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measurement_data]);

  // handle auto string to automatically add the specified measurement to the tabs
  useEffect(() => {
    // auto_string
    if (auto_string_execution) {
      try {
        let auto_object = process_auto_string(auto_string);
        console.log('auto_object', auto_object);
        let provided_measurement_id = auto_object.measurement_id;
        if (provided_measurement_id !== null) {
          let selection = measurement_data.find((item) => item.id === provided_measurement_id);
          if (!selection) {
            throw new Error(`Could not find ${provided_measurement_id}`);
          }
          let measurement_id = selection.id;
          add_measurement_tab(measurement_id);
        }
      } catch (error) {
        invalid_auto_string(auto_string, error);
      } finally {
        set_auto_string_execution(false);
        set_auto_string_loading_drawer_open(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function add_measurement_tab(id_clicked) {
    /** callback for when a new tab is created */

    // create tab
    if (!session_tabs.map((item) => item.id).includes(id_clicked)) {
      set_session_tabs(session_tabs.concat([{ id: id_clicked }]));
    }
    set_current_tab({ id: id_clicked });
  }

  function close_tab(event, id) {
    /** event handler for the close button on tabs; returns to timeline tab if the current tab is being closed */

    // prevent event propogation
    event.preventDefault();
    event.stopPropagation();
    // get new tabs without the closed one
    let new_session_tabs = session_tabs.filter((value) => {
      return value.id !== id;
    });
    // close TabMeasurementsView if all tabs are closed
    if (new_session_tabs.length !== 0) {
      let go_to_tab = current_tab.id === id ? new_session_tabs[0] : current_tab;
      set_current_tab(go_to_tab);
    }
    set_session_tabs(new_session_tabs);
  }

  function generate_tabs() {
    /** generates selectable tabs at the top of the card */

    // special case for timeline tab
    let timeline_tab = (
      <Tab
        key="timeline"
        value="timeline"
        label={
          <Box>
            {'Measurement'}
            <br />
            {'Timeline'}
          </Box>
        }
      />
    );

    let tabs = [];
    // add additional tabs based on info in session_tabs
    session_tabs.forEach((item, idx) => {
      if (item.id === 'timeline') {
        tabs.push(timeline_tab);
        return;
      }
      let label = item.id.match('(....).*')[1] + '...';
      let label_date = get_date_string(measurement_data.find((meas) => meas.id === item.id).created.at.date_object);
      let close_tab_icon_button = use_timeline ? (
        <Box sx={SX('flex', 'center_items', 'm3')}>
          <Box
            component="img"
            src="/static/close_icon.svg"
            sx={SX('tab_close')}
            onClick={(event) => close_tab(event, item.id)}
          />
        </Box>
      ) : null;
      tabs.push(
        <Tab
          key={idx}
          value={item.id}
          label={
            <Box sx={SX('flex')}>
              <Box sx={SX('flex', 'column')}>
                {label}
                <br />
                {label_date}
              </Box>
              {close_tab_icon_button}
            </Box>
          }
        />
      );
    });
    // return tabs component
    return (
      <Tabs
        sx={SX({ height: tab_height })}
        value={current_tab.id}
        onChange={(event, value) => {
          set_current_tab(session_tabs.find((item) => item.id === value));
        }}
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabs}
      </Tabs>
    );
  }

  function generate_tab_display(use_timeline) {
    /** generate content to display based on selected tab */

    // contain display elements
    let tab_display = [];

    if (use_timeline) {
      // timeline display persist state between tab swithes with Collapse element
      let metadata = (
        <Box sx={SX('flex', 'grow1')}>
          <UserMetadataView measurement_data={measurement_data} />
        </Box>
      );
      let refresh_button = (
        <Button
          key={'refresh_button'}
          onClick={() => {
            mutate_data();
          }}
          sx={SX('button', 'm10')}
        >
          REFRESH
        </Button>
      );
      let utility_belt = (
        <Box key="utility_belt" sx={SX('flex', 'sub_section', 'm10')}>
          {metadata}
          {refresh_button}
        </Box>
      );
      tab_display.push(
        <Collapse in={'timeline' === current_tab.id} key={'timeline'} timeout={0}>
          {utility_belt}
          <ShareDivaLinkBox individual_id={user_id} key={'ShareDivaLinkBox'} />
          <BpTimeline key="timeline" measurement_data={measurement_data} add_measurement_tab={add_measurement_tab} />
        </Collapse>
      );
    }

    // current tab (only contains this if the current tab is NOT 'timeline')
    if (current_tab.id !== 'timeline') {
      tab_display.push(
        <Box key={current_tab.id}>
          <MeasurementView
            key={current_tab.id}
            measurement_id={current_tab.id}
            this_measurement_data={measurement_data.find((meas) => meas.id === current_tab.id)}
            user_id={user_id}
            mutate_data={mutate_data}
            lock_views={lock_views}
            set_lock_views={set_lock_views}
          />
        </Box>
      );
    }

    // return content
    return tab_display;
  }

  // return blank view if no tabs are present
  if (session_tabs.length === 0) {
    return (
      <Box sx={SX('flex', 'center')}>
        <br />
        No tabs found
      </Box>
    );
  }

  let tabs = generate_tabs();
  let tab_display = generate_tab_display(use_timeline);
  let content = (
    <Box sx={SX('flex')}>
      <Box sx={SX({ width: 1.0 })}>
        {tabs}
        {tab_display}
      </Box>
    </Box>
  );

  return content;
}
