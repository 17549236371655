import Router from './Router';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, createContext, useState, useRef } from 'react';
import Unauthorized from './pages/unauthorized';
import LoadingHeart from './components/ui/loading-heart';
// mui
import { Snackbar } from '@mui/material';

export default function App() {
  // auth0
  const { error, isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  // bool: set to true to display snackbar (reverts to false automatically after 1.5s)
  let [snackbar_open, set_snackbar_open] = useState(false);

  // message to show in snackbar
  let [snack_message, set_snack_message] = useState('---');

  // ref to keep track of the current snackbar message
  let snack_ctr = useRef(0);

  // function to update message and temporarily display the snackbar
  let display_snackbar_message = function (message) {
    // set message and open snackbar
    set_snack_message(message);
    set_snackbar_open(true);
    // close the snackbar after 1.5s unless another snackbar was opened
    snack_ctr.current += 1;
    let timeout_ctr = snack_ctr.current;
    setTimeout(() => {
      if (timeout_ctr === snack_ctr.current){
        set_snackbar_open(false);
      }
    }, 1500)
  };

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <LoadingHeart />
      </div>
    );
  }

  if (error) {
    return <Unauthorized />;
  }

  // snackbar to indicate something was copied
  let copy_snack = (
    <Snackbar
      open={snackbar_open}
      message={snack_message}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    />
  );

  return (
    <SnackMessageContext.Provider value={display_snackbar_message}>
      <Router />
      {copy_snack}
    </SnackMessageContext.Provider>
  );
}

export let SnackMessageContext = createContext();
