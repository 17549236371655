// d3
import * as d3 from 'd3';
// zach
import { STYLES } from '../sx_styling';

// -------------------------------------------------------------------------------------------------------

export function calculate_scales(bto, measurement_data, canvas_dims, zoom) {
  /** calculate scales used to format all elements in the chart */

  // x
  bto.x_scale.current = d3.scaleLinear([0, 100], [0, canvas_dims[0]]);
  // y
  bto.y_scale.current = d3.scaleLinear([0, 100], [0, canvas_dims[1]]);
  // datetime
  let date_time_extent = d3.extent(measurement_data.map((meas) => +meas.created.at.date_object));
  date_time_extent = apply_extent_margins(date_time_extent);
  bto.datetime_scale.current = d3.scaleTime(date_time_extent, [
    bto.x_scale.current(bto.datetime_left_extent),
    bto.x_scale.current(bto.datetime_right_extent),
  ]);
  for (let zoom_iter of zoom) {
    bto.datetime_scale.current.domain([
      bto.datetime_scale.current.invert(zoom_iter[0]),
      bto.datetime_scale.current.invert(zoom_iter[1]),
    ]);
  }
  // pressure
  let pressure_extent = d3.extent(
    measurement_data.reduce((p, c) => p.concat([c.systolicBloodPressure, c.diastolicBloodPressure]), [])
  );
  pressure_extent = apply_extent_margins(pressure_extent);
  bto.pressure_scale.current = d3.scaleLinear(pressure_extent, [
    bto.y_scale.current(bto.pressure_bottom_extent),
    bto.y_scale.current(bto.pressure_top_extent),
  ]);
  for (let zoom_iter of zoom) {
    bto.pressure_scale.current.domain([
      bto.pressure_scale.current.invert(zoom_iter[2]),
      bto.pressure_scale.current.invert(zoom_iter[3]),
    ]);
  }
}

function apply_extent_margins(extent, amount = 0.05) {
  /** add margins to the provided extent by returning a shrunken extent */

  let range = extent[1] - extent[0];
  extent[0] -= range * amount;
  extent[1] += range * amount;
  return extent;
}

export function init_bp_markers(bto, measurement_data, add_measurement_tab) {
  /** create markers for both enumerate and timeline modes */

  // get svg
  let svg = d3.select(bto.svg_ref.current);

  // create bp_markers to contain all markers (1 per bp measurement)
  let bp_markers = svg.append('g').attr('id', 'bp_markers');
  bp_markers
    .selectAll()
    .data(measurement_data)
    // enter function for each marker
    .join(function (enter) {
      let marker = enter
        .append('g')
        .attr('id', 'marker') // id used to select markers
        .attr('scale', 0) // used to calculate marker-expanding animation
        .attr('y_shift', 0) // used to calculate marker-y-shifting animation
        .style('cursor', 'pointer') // make marker show pointer to imply it's clickable
        .on('mouseenter', null) // reset event
        .on('mouseleave', null); // reset event

      // class bad_bp_value markers appropriately
      marker
        .filter(function (d) {
          return d.bad_bp_value;
        })
        .classed('bad_bp_value', true);

      // class tags appropriately
      marker
        .filter(function (d) {
          return d.comment !== '';
        })
        .classed('comment_flag', true);
      marker
        .filter(function (d) {
          return d.tags.review_flag;
        })
        .classed('review_flag', true);
      marker
        .filter(function (d) {
          return Object.values(d.tags).includes(true);
        })
        .classed('tag', true);

      // make marker show pointer to imply it's clickable
      marker.on('click', function (event, d) {
        event.stopPropagation();
        add_measurement_tab(d.id);
      });

      // animate mouseover; slightly enlarge marker circles
      marker
        .on('mouseenter', function (event, d) {
          d3.select(this)
            .select('#circle_marker')
            .transition()
            .attr('r', function (d) {
              let radius = parseFloat(d3.select(this.parentNode).attr('r'));
              return `${radius + bto.y_scale.current(3)}px`;
            });
        })
        .on('mouseleave', function (event) {
          d3.select(this)
            .select('#circle_marker')
            .transition()
            .attr('r', function (d) {
              let radius = parseFloat(d3.select(this.parentNode).attr('r'));
              return `${radius}px`;
            });
        });

      return marker;
    });
}

export function draw_bp_markers(bto, lock_views) {
  /** complete drawing of the bp_markers for enumerate and timeline views */

  let svg = d3.select(bto.svg_ref.current);

  let markers = svg.select('g#bp_markers').selectAll('#marker');

  // marker body
  markers
    .append('circle')
    .attr('id', 'circle_marker')
    .attr('fill', function (d) {
      return lock_views.find((meas) => meas.id === d.id)
        ? STYLES.riva_background_3.background
        : STYLES.riva_background_1.background;
    })
    .attr('stroke-width', '3px')
    .attr('stroke', 'black')
    .attr('cx', function (d) {
      return `${d3.select(this.parentNode).attr('x')}px`;
    })
    .attr('cy', function (d) {
      return `${d3.select(this.parentNode).attr('y')}px`;
    })
    .attr('r', function (d) {
      return `${d3.select(this.parentNode).attr('r')}px`;
    });

  // style tags
  svg.selectAll('.tag').selectAll('#circle_marker').attr('stroke', 'magenta');
  svg.selectAll('.review_flag').selectAll('#circle_marker').attr('stroke', 'red');
  svg.selectAll('.bad_bp_value').selectAll('#circle_marker').attr('opacity', 0.5);

  // systolic text
  markers
    .append('text')
    .attr('id', 'systolic')
    .classed('marker_text', true)
    .text(function (d) {
      return Math.round(d.systolicBloodPressure).toString();
    });

  // diastolic text
  markers
    .append('text')
    .attr('id', 'diastolic')
    .classed('marker_text', true)
    .text(function (d) {
      return Math.round(d.diastolicBloodPressure).toString();
    })
    .style('text-anchor', 'middle')
    .attr('dy', '1em'); // move diastolic text down 1 line

  // extra text
  markers
    .append('text')
    .attr('id', 'details')
    .classed('marker_text', true)
    .text(function (d) {
      let id_label = '????';
      try {
        id_label = d.id.match('(....).*')[1].slice(0, 4) + '...';
      } finally {
        return id_label;
      }
    })
    .style('text-anchor', 'middle')
    .attr('dy', '2em'); // move details text down 2 lines

  // calibration text
  markers
    .filter(function (d) {
      return d.calibrationReadingId !== '';
    })
    .append('text')
    .attr('id', 'calibration_text')
    .classed('marker_text', true)
    .text('CAL')
    .attr('dy', '-1em'); // move calibration text up 1 line

  // all text of class marker_text
  markers
    .selectAll('.marker_text')
    .attr('x', function (d) {
      return `${d3.select(this.parentNode).attr('x')}px`;
    })
    .attr('y', function (d) {
      return `${d3.select(this.parentNode).attr('y')}px`;
    })
    .style('text-anchor', 'middle');
}

export function add_pressure_axis(bto, ylabel = true) {
  /** include bp pressure y axis to svg */

  // get svg
  let svg = d3.select(bto.svg_ref.current);

  let pressure_axis = d3.axisLeft().scale(bto.pressure_scale.current);
  svg
    .append('g')
    .attr('id', 'pressure_axis')
    .attr('transform', `translate(${bto.x_scale.current(10)},${bto.y_scale.current(0)})`)
    .call(pressure_axis);
  svg.selectAll('g#pressure_axis text').attr('font-size', `${bto.axis_font_size}em`).classed('no_select', true);
  // ylabel
  if (ylabel) {
    svg
      .append('text')
      .attr('id', 'ylabel')
      .classed('no_select', true)
      .text('Pressure (mmHg)')
      .style('text-anchor', 'middle')
      .attr('x', bto.x_scale.current(4))
      .attr('y', bto.y_scale.current(50))
      .attr('transform', `rotate(-90, ${bto.x_scale.current(4)}, ${bto.y_scale.current(50)})`)
      .attr('font-size', `${bto.axis_font_size}em`);
  }
}

export function add_datetime_axis(bto, level, xlabel = true) {
  /** include datetime x axis to svg */

  // get svg
  let svg = d3.select(bto.svg_ref.current);

  let datetime_axis = d3.axisBottom().scale(bto.datetime_scale.current);
  let datetime_axis_g = svg.append('g').attr('id', 'datetime_axis');
  // height of axis (level)
  if (level) {
    datetime_axis_g.attr('transform', `translate(0,${bto.y_scale.current(level)})`);
  }
  datetime_axis_g.call(datetime_axis);
  // format text
  svg
    .selectAll('g#datetime_axis text')
    .attr('transform', function (d) {
      return `translate(${this.getBBox().height * -2},${this.getBBox().height})rotate(-45)`;
    })
    .attr('font-size', `${bto.axis_font_size}em`)
    .classed('no_select', true);
  // xlabel
  if (xlabel) {
    svg
      .append('text')
      .classed('no_select', true)
      .attr('id', 'xlabel')
      .text('DateTime')
      .style('text-anchor', 'middle')
      .attr('x', bto.x_scale.current(50))
      .attr('y', bto.y_scale.current(level + 16))
      .attr('font-size', `${bto.axis_font_size}em`);
  }
}
