/* eslint-disable */

import _ from 'lodash';

let riva_background_1 = 'rgb(180,180,250)';
let riva_background_2 = 'rgb(215, 215, 255)';
let riva_background_3 = 'rgb(250, 180, 180)';
let riva_background_4 = 'rgb(250,250,180)';
let riva_background_5 = 'rgb(255, 255, 215)';
let light_gray_background = 'rgb(212, 212, 212)';
let sub_light_gray = '#b4b4b4';
let sidebar_width = '15vw';

export let STYLES = {
  // general styles ------------------------

  riva_background_1: {
    background: riva_background_1,
  },

  riva_background_2: {
    background: riva_background_2,
  },

  riva_background_3: {
    background: riva_background_3,
  },

  riva_background_4: {
    background: riva_background_4,
  },

  riva_background_5: {
    background: riva_background_5,
  },

  light_gray_background: {
    background: light_gray_background,
  },

  card_title: {
    height: '4vh',
  },

  button: {
    background: riva_background_2,
    border: '1px solid #777474',
    borderRadius: '10px',
    color: '#414141',
    '&:hover': {
      background: light_gray_background,
    },
    cursor: 'pointer',
  },

  toggle_on_button: {
    background: riva_background_1,
    border: '1px solid #777474',
    borderRadius: '10px',
    color: '#414141',
    '&:hover': {
      background: riva_background_1,
    },
    cursor: 'pointer',
  },

  toggle_off_button: {
    background: 'rgb(255, 255, 255)',
    border: '1px solid #777474',
    borderRadius: '10px',
    color: '#414141',
    '&:hover': {
      background: light_gray_background,
    },
    cursor: 'pointer',
  },

  selected_button: {
    background: riva_background_3,
    border: '1px solid #777474',
    borderRadius: '10px',
    color: '#414141',
    '&:hover': {
      background: riva_background_3,
    },
    cursor: 'pointer',
  },

  sub_section: {
    border: '1px solid #b4b4b4',
    borderRadius: '10px',
  },

  transition: {
    // 'transform': 'scale(1.0, 1.0)',
    transition: 'height 1s',
  },

  // specific components -------------------

  row_a: {
    background: 'rgb(155, 155, 180)',
  },

  row_b: {
    background: riva_background_2,
  },

  minimize_close_button: {
    border: '1px solid #777474',
    borderRadius: '10px',
    background: riva_background_2,
    color: '#414141',
  },

  date_slider_margins: {
    m: '20px',
  },

  tab_close: {
    width: '20px',
    height: '20px',
  },

  SideBar_icon: {
    width: 25,
    height: 25,
  },

  Modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },

  ContentCard: {
    mt: '10px',
    mb: '10px',
  },

  selected_sidebar: {
    background: riva_background_1,
    '&:hover': {
      background: riva_background_1,
    },
  },

  image_list_item: {
    // width: 'auto',
    // overflow: 'auto',
    // overflow: 'hidden'
    width: '100px',
  },

  user_grid_data_grid: {
    '.MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '.col_head:focus': {
      outline: 'none',
    },
    '.col_head:focus-within': {
      outline: 'none',
    },
    '.MuiDataGrid-row:hover': {
      background: riva_background_3,
      cursor: 'pointer',
    },
    '.highlight_row': {
      background: riva_background_3,
    },
  },

  user_grid_data_grid_main: {
    background: riva_background_1,
    '.MuiDataGrid-row': {
      background: riva_background_2,
    },
  },

  user_grid_data_grid_recents: {
    background: riva_background_4,
    '.MuiDataGrid-row': {
      background: riva_background_5,
    },
  },

  // general styling ------------------------

  m0: {
    m: '0px',
  },
  m3: {
    m: '3px',
  },
  m10: {
    m: '10px',
  },
  m25: {
    m: '25px',
  },

  hover_color: {
    '&:hover': {
      background: light_gray_background,
    },
  },

  pointer: {
    cursor: 'pointer',
  },

  transparent: {
    background: 'rgb(0, 0, 0, 0)',
  },

  border1: {
    border: '1px solid rgb(0, 0, 0)',
  },

  border1_red: {
    border: '1px solid rgb(255, 0, 0)',
  },

  border1_blue: {
    border: '1px solid rgb(0, 0, 255)',
  },

  border1_gray: {
    border: '1px solid rgb(150, 150, 150)',
  },

  border1_sub_light_gray: {
    border: `1px solid ${sub_light_gray}`,
  },

  divider_border: {
    background: 'gray',
    cursor: 'col-resize',
  },

  curve_border: {
    borderRadius: '10px',
  },

  width100: {
    width: 1.0,
  },
  width70: {
    width: 0.7,
  },
  width50: {
    width: 0.5,
  },
  width30: {
    width: 0.3,
  },
  width20: {
    width: 0.2,
  },
  width10: {
    width: 0.1,
  },
  height60: {
    height: '60px',
  },
  height400px: {
    height: '400px',
  },
  height100pct: {
    height: '100%',
  },

  color_black: {
    color: 'rgb(0, 0, 0)',
  },

  color_red: {
    color: 'rgb(255, 0, 0)',
  },

  color_gray: {
    color: '#787878',
  },

  color_light_gray: {
    color: light_gray_background,
  },

  color_magenta: {
    color: 'magenta',
  },

  // flex styles -------------------

  flex: {
    display: 'flex',
  },

  inline_flex: {
    display: 'inline-flex',
  },

  grow1: {
    flexGrow: 1,
    flexBasis: 0,
  },

  grow2: {
    flexGrow: 2,
    flexBasis: 0,
  },

  grow3: {
    flexGrow: 3,
    flexBasis: 0,
  },

  center: {
    justifyContent: 'center',
  },

  center_items: {
    alignItems: 'center',
  },

  left: {
    justifyContent: 'left',
  },

  right: {
    justifyContent: 'right',
  },

  right_items: {
    alignItems: 'right',
  },

  column: {
    flexDirection: 'column',
  },
};

export function SX(...args) {
  /** create sx object from provided strings and/or objects */

  let sx = {};
  args.forEach((item, idx) => {
    if (typeof item == 'string') {
      if (!Object.keys(STYLES).includes(item)) {
        throw `Unrecognized style ${item}`;
      }
      sx = _.merge(sx, STYLES[item]);
    } else if (typeof item == 'object') {
      sx = _.merge(sx, item);
    } else {
      throw `Incompatible argument for SX: ${item}`;
    }
  });
  return sx;
}
