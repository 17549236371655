import React, { useState } from 'react';
import PropTypes from 'prop-types';
// mui
import { Box } from '@mui/material';
// zach
import SideBar from './SideBar';
import { SX } from './utils/sx_styling';

// -------------------------------------------------------------------------------------------------------

export default function Page({
  content, // react component to be rendered in page
}) {
  /** provides a page to be directed from Router.js. Page provides a navigation sidebar from Sidebar.js
   * and content provided in its constructor's props
   */

  // keep track of sidebar width in state
  let [sidebar_width, set_sidebar_width] = useState(15);

  // return sidebar and page's content
  return (
    <Box>
      <SideBar sidebar_width={sidebar_width} set_sidebar_width={set_sidebar_width} />
      <Box sx={SX({ ml: sidebar_width + 'vw' })}>{content}</Box>
    </Box>
  );
}

Page.propTypes = {
  content: PropTypes.object.isRequired, // react component to be rendered in page
};
