// d3
import * as d3 from 'd3';
// zach
import { add_pressure_axis, add_datetime_axis } from './draw_bp_chart_utils';

// -------------------------------------------------------------------------------------------------------

export function draw_bp_line_chart(
  bto,
  measurement_data,
  add_measurement_tab,
  ewma_state,
  zoom,
  set_zoom,
  set_ewma_state
) {
  /** helper function to draw a bp line chart in the BpTimelineChart element's svg */

  // get svg
  let svg = d3.select(bto.svg_ref.current);

  // setup values
  let regular_r = bto.x_scale.current(0.75);
  let highlight_r = bto.x_scale.current(1.25);

  // toggle/switch moving average state
  function iterate_ma_state(num) {
    set_ewma_state(!ewma_state);
  }

  // std systolic path range
  let line_data_systolic_MA_path_range = measurement_data
    .filter((meas) => !meas.bad_bp_value)
    .map((meas) => {
      return [
        bto.datetime_scale.current(meas.created.at.date_object),
        bto.pressure_scale.current(meas.systolic_anomaly_threshold[1]),
      ];
    });
  line_data_systolic_MA_path_range = line_data_systolic_MA_path_range.concat(
    measurement_data
      .filter((meas) => !meas.bad_bp_value)
      .reverse()
      .map((meas) => {
        return [
          bto.datetime_scale.current(meas.created.at.date_object),
          bto.pressure_scale.current(meas.systolic_anomaly_threshold[0]),
        ];
      })
  );
  svg.append('path').classed('systolic_MA_path_range', true).attr('d', d3.line()(line_data_systolic_MA_path_range));

  // std diastolic path range
  let line_data_diastolic_MA_path_range = measurement_data
    .filter((meas) => !meas.bad_bp_value)
    .map((meas) => {
      return [
        bto.datetime_scale.current(meas.created.at.date_object),
        bto.pressure_scale.current(meas.diastolic_anomaly_threshold[1]),
      ];
    });
  line_data_diastolic_MA_path_range = line_data_diastolic_MA_path_range.concat(
    measurement_data
      .filter((meas) => !meas.bad_bp_value)
      .reverse()
      .map((meas) => {
        return [
          bto.datetime_scale.current(meas.created.at.date_object),
          bto.pressure_scale.current(meas.diastolic_anomaly_threshold[0]),
        ];
      })
  );
  svg.append('path').classed('diastolic_MA_path_range', true).attr('d', d3.line()(line_data_diastolic_MA_path_range));

  // graph area to control clicks
  svg
    .append('rect')
    .classed('graph_area', true)
    .attr('x', bto.x_scale.current(bto.datetime_left_extent))
    .attr('y', bto.y_scale.current(bto.pressure_top_extent))
    .attr('width', bto.x_scale.current(bto.datetime_right_extent) - bto.x_scale.current(bto.datetime_left_extent))
    .attr('height', bto.y_scale.current(bto.pressure_bottom_extent) - bto.y_scale.current(bto.pressure_top_extent))
    .attr('opacity', 0.0)
    .style('cursor', 'crosshair');

  // systolic curve
  svg
    .append('path')
    .attr('id', 'systolic_curve')
    .classed('line_path', true)
    .datum(measurement_data)
    .attr(
      'd',
      d3
        .line()
        .x(function (d) {
          return bto.datetime_scale.current(d.created.at.date_object);
        })
        .y(function (d) {
          return bto.pressure_scale.current(d.systolicBloodPressure);
        })
    );
  // diastolic curve
  svg
    .append('path')
    .attr('id', 'diastolic_curve')
    .classed('line_path', true)
    .datum(measurement_data)
    .attr(
      'd',
      d3
        .line()
        .x(function (d) {
          return bto.datetime_scale.current(d.created.at.date_object);
        })
        .y(function (d) {
          return bto.pressure_scale.current(d.diastolicBloodPressure);
        })
    );

  // markers on lines
  let line_markers = svg.append('g').attr('id', 'line_markers');
  line_markers
    .selectAll()
    .data(measurement_data)
    .join(function (enter) {
      let measurement_marker_set = enter
        .append('g')
        .classed('measurement_marker_set', true)
        .attr('id', function (d, i) {
          return 'measurement_marker_set_' + i;
        });
      measurement_marker_set
        .filter(function (d) {
          return d.bad_bp_value;
        })
        .classed('bad_bp_value', true);
      measurement_marker_set
        .filter(function (d) {
          return Object.values(d.tags).includes(true);
        })
        .classed('tag', true);
      measurement_marker_set.append('circle').classed('data_marker', true).classed('systolic_marker', true);
      measurement_marker_set.append('circle').classed('data_marker', true).classed('diastolic_marker', true);
      measurement_marker_set
        .append('path')
        .classed('measurement_marker_set_path', true)
        .attr('d', function (d, i) {
          return d3.line()([
            [
              bto.datetime_scale.current(d.created.at.date_object),
              bto.pressure_scale.current(d.diastolicBloodPressure),
            ],
            [bto.datetime_scale.current(d.created.at.date_object), bto.pressure_scale.current(d.systolicBloodPressure)],
          ]);
        });

      measurement_marker_set
        .style('cursor', 'pointer')
        .on('click', function (event, d) {
          event.stopPropagation();
          add_measurement_tab(d.id);
        })
        .on('mouseover', function (d, i) {
          d3.select(this).selectAll('.data_marker').transition().duration('150').attr('r', highlight_r);
        })
        .on('mouseout', function (d, i) {
          d3.select(this).selectAll('.data_marker').transition().duration('150').attr('r', regular_r);
        });
    });

  // moving average
  svg
    .append('path')
    .classed('systolic_MA_path', true)
    .datum(measurement_data.filter((meas) => !meas.bad_bp_value))
    .attr(
      'd',
      d3
        .line()
        .x(function (d) {
          return bto.datetime_scale.current(d.created.at.date_object);
        })
        .y(function (d) {
          return bto.pressure_scale.current(ewma_state ? d.systolic_ewma : d.systolic_ma);
        })
    );
  svg
    .append('path')
    .classed('diastolic_MA_path', true)
    .datum(measurement_data.filter((meas) => !meas.bad_bp_value))
    .attr(
      'd',
      d3
        .line()
        .x(function (d) {
          return bto.datetime_scale.current(d.created.at.date_object);
        })
        .y(function (d) {
          return bto.pressure_scale.current(ewma_state ? d.diastolic_ewma : d.diastolic_ma);
        })
    );

  // rects to form frame around graphed data
  svg
    .append('rect')
    .classed('frame', true)
    .attr('x', bto.x_scale.current(0))
    .attr('y', bto.y_scale.current(0))
    .attr('width', bto.x_scale.current(100))
    .attr('height', bto.y_scale.current(bto.pressure_top_extent))
    .attr('fill', 'lightGray');
  svg
    .append('rect')
    .classed('frame', true)
    .attr('x', bto.x_scale.current(0))
    .attr('y', bto.y_scale.current(bto.pressure_bottom_extent))
    .attr('width', bto.x_scale.current(100))
    .attr('height', bto.y_scale.current(100))
    .attr('fill', 'lightGray');
  svg
    .append('rect')
    .classed('frame', true)
    .attr('x', bto.x_scale.current(0))
    .attr('y', bto.y_scale.current(0))
    .attr('width', bto.x_scale.current(bto.datetime_left_extent))
    .attr('height', bto.y_scale.current(100))
    .attr('fill', 'lightGray');
  svg
    .append('rect')
    .classed('frame', true)
    .attr('x', bto.x_scale.current(bto.datetime_right_extent))
    .attr('y', bto.y_scale.current(0))
    .attr('width', bto.x_scale.current(100))
    .attr('height', bto.y_scale.current(100))
    .attr('fill', 'lightGray');
  svg
    .selectAll('.frame')
    .style('cursor', null)
    .on('click', function (event) {
      event.stopPropagation();
    })
    .on('mousedown', function (event) {
      event.stopPropagation();
    });

  // datetime axis
  add_datetime_axis(bto, 80);
  // pressure axis
  add_pressure_axis(bto);

  // legend
  let legend = svg.append('g').attr('id', 'line_legend');
  // systolic
  legend
    .append('text')
    .attr('id', 'legend')
    .text('Systolic')
    .attr('x', bto.x_scale.current(92.5))
    .attr('y', bto.y_scale.current(15))
    .style('text-anchor', 'middle')
    .classed('no_select', true)
    .attr('font-size', `${bto.axis_font_size - 0.3}em`);
  legend
    .append('circle')
    .classed('legend_marker', true)
    .classed('systolic_marker', true)
    .attr('cx', `${bto.x_scale.current(92.5)}px`)
    .attr('cy', `${bto.y_scale.current(20)}px`);
  // systolic MA
  legend
    .append('text')
    .attr('id', 'legend')
    .text(ewma_state ? 'Systolic EWMA' : 'Systolic MA')
    .attr('x', bto.x_scale.current(92.5))
    .attr('y', bto.y_scale.current(30))
    .style('text-anchor', 'middle')
    .classed('no_select', true)
    .attr('font-size', `${bto.axis_font_size - 0.3}em`);
  legend
    .append('path')
    .classed('systolic_MA_path', true)
    .attr(
      'd',
      d3.line()([
        [bto.x_scale.current(90), bto.y_scale.current(35)],
        [bto.x_scale.current(95), bto.y_scale.current(35)],
      ])
    );
  legend
    .append('svg:image')
    .attr('x', bto.x_scale.current(95.5))
    .attr('y', bto.y_scale.current(33))
    .attr('width', bto.x_scale.current(4))
    .attr('height', bto.y_scale.current(4))
    .attr('xlink:href', '/static/iterate_right.svg')
    .style('cursor', 'pointer')
    .on('click', function (event) {
      iterate_ma_state(1);
    });
  legend
    .append('svg:image')
    .attr('x', bto.x_scale.current(85.5))
    .attr('y', bto.y_scale.current(33))
    .attr('width', bto.x_scale.current(4))
    .attr('height', bto.y_scale.current(4))
    .attr('xlink:href', '/static/iterate_left.svg')
    .style('cursor', 'pointer')
    .on('click', function (event) {
      iterate_ma_state(1);
    });
  // diastolic
  legend
    .append('text')
    .attr('id', 'legend')
    .text('Diastolic')
    .attr('x', bto.x_scale.current(92.5))
    .attr('y', bto.y_scale.current(50))
    .style('text-anchor', 'middle')
    .classed('no_select', true)
    .attr('font-size', `${bto.axis_font_size - 0.3}em`);
  legend
    .append('circle')
    .classed('legend_marker', true)
    .classed('diastolic_marker', true)
    .attr('cx', `${bto.x_scale.current(92.5)}px`)
    .attr('cy', `${bto.y_scale.current(55)}px`);
  // diastolic MA
  legend
    .append('text')
    .attr('id', 'legend')
    .text(ewma_state ? 'Diastolic EWMA' : 'Diastolic MA')
    .attr('x', bto.x_scale.current(92.5))
    .attr('y', bto.y_scale.current(65))
    .style('text-anchor', 'middle')
    .classed('no_select', true)
    .attr('font-size', `${bto.axis_font_size - 0.3}em`);
  legend
    .append('path')
    .classed('diastolic_MA_path', true)
    .attr(
      'd',
      d3.line()([
        [bto.x_scale.current(90), bto.y_scale.current(70)],
        [bto.x_scale.current(95), bto.y_scale.current(70)],
      ])
    );
  legend
    .append('svg:image')
    .attr('x', bto.x_scale.current(95.5))
    .attr('y', bto.y_scale.current(68))
    .attr('width', bto.x_scale.current(4))
    .attr('height', bto.y_scale.current(4))
    .attr('xlink:href', '/static/iterate_right.svg')
    .style('cursor', 'pointer')
    .on('click', function (event) {
      iterate_ma_state(1);
    });
  legend
    .append('svg:image')
    .attr('x', bto.x_scale.current(85.5))
    .attr('y', bto.y_scale.current(68))
    .attr('width', bto.x_scale.current(4))
    .attr('height', bto.y_scale.current(4))
    .attr('xlink:href', '/static/iterate_left.svg')
    .style('cursor', 'pointer')
    .on('click', function (event) {
      iterate_ma_state(-1);
    });
  // zoom reset button
  if (zoom.length !== 0) {
    svg
      .append('text')
      .attr('x', bto.x_scale.current(92.5))
      .attr('y', bto.y_scale.current(90))
      .text('RESET')
      .style('text-anchor', 'middle')
      .classed('no_select', true)
      .attr('fill', 'orange')
      .style('cursor', 'pointer')
      .on('click', function (event) {
        event.stopPropagation();
        set_zoom([]);
      })
      .on('mousedown', function (event) {
        event.stopPropagation();
      })
      .on('mouseup', function (event) {
        event.stopPropagation();
      });
  }

  // format/style lines and markers
  svg.selectAll('.line_path').attr('fill', 'none').attr('stroke', 'none').attr('stroke-width', 1.5);
  svg.selectAll('.systolic_MA_path').attr('fill', 'none').attr('stroke', 'red').attr('stroke-width', 2.5);
  svg.selectAll('.diastolic_MA_path').attr('fill', 'none').attr('stroke', 'blue').attr('stroke-width', 2.5);
  svg.selectAll('.systolic_MA_path_range').attr('fill', 'red').attr('opacity', 0.5);
  svg.selectAll('.diastolic_MA_path_range').attr('fill', 'blue').attr('opacity', 0.5);
  svg.selectAll('.systolic_marker,.diastolic_marker').attr('r', regular_r).attr('stroke', 'black');
  svg.selectAll('.systolic_marker').attr('fill', 'red');
  svg.selectAll('.diastolic_marker').attr('fill', 'blue');
  svg
    .selectAll('.measurement_marker_set_path')
    .attr('fill', 'none')
    .attr('stroke', 'black')
    .attr('stroke-width', 1.0)
    .attr('opacity', 0.5);
  svg.selectAll('.bad_bp_value').selectAll('circle,path').attr('stroke', 'gray').attr('opacity', 0.5);
  svg.selectAll('.tag').selectAll('circle,path').attr('stroke', 'magenta');
  svg.selectAll('.data_marker').attr('cx', function (d) {
    return `${bto.datetime_scale.current(d.created.at.date_object)}px`;
  });
  svg.selectAll('.systolic_marker.data_marker').attr('cy', function (d) {
    return `${bto.pressure_scale.current(d.systolicBloodPressure)}px`;
  });
  svg.selectAll('.diastolic_marker.data_marker').attr('cy', function (d) {
    return `${bto.pressure_scale.current(d.diastolicBloodPressure)}px`;
  });

  // zoom
  svg.append('rect').attr('id', 'zoom_window').attr('fill', 'cyan').attr('stroke', 'grey').attr('opacity', 0.5);
  let isDown = false;
  let zoom_pt_1 = null;
  let zoom_pt_2 = null;
  svg.selectAll('.graph_area').on('mousedown', function (event) {
    event.stopPropagation();
    isDown = true;
    let coordinates = d3.pointer(event, svg.node());
    zoom_pt_1 = coordinates;
    zoom_pt_2 = null;
  });
  svg
    .on('mousemove', function (event) {
      event.stopPropagation();
      if (isDown) {
        let coordinates = d3.pointer(event, svg.node());
        zoom_pt_2 = coordinates;
        let x = Math.min(zoom_pt_1[0], zoom_pt_2[0]);
        let y = Math.min(zoom_pt_1[1], zoom_pt_2[1]);
        let w = Math.max(zoom_pt_1[0], zoom_pt_2[0]) - x;
        let h = Math.max(zoom_pt_1[1], zoom_pt_2[1]) - y;
        svg
          .select('#zoom_window')
          .attr('x', `${x}px`)
          .attr('y', `${y}px`)
          .attr('width', `${w}px`)
          .attr('height', `${h}px`);
      }
    })
    .on('mouseup', function (event) {
      event.stopPropagation();
      if (isDown) {
        // execute zoom
        if (zoom_pt_1 && zoom_pt_2) {
          let tweak = [];
          tweak.push(Math.min(zoom_pt_1[0], zoom_pt_2[0]));
          tweak.push(Math.max(zoom_pt_1[0], zoom_pt_2[0]));
          tweak.push(Math.max(zoom_pt_1[1], zoom_pt_2[1]));
          tweak.push(Math.min(zoom_pt_1[1], zoom_pt_2[1]));
          set_zoom(zoom.concat([tweak]));
        }
        reset_zoom_window();
      }
    })
    .on('mouseleave', function (event) {
      event.stopPropagation();
      reset_zoom_window();
    });

  function reset_zoom_window() {
    isDown = false;
    zoom_pt_1 = null;
    zoom_pt_2 = null;
    svg.select('#zoom_window').attr('x', null).attr('y', null).attr('width', null).attr('height', null);
  }
}
