// d3
import * as d3 from 'd3';
// zach
import { get_date_string } from '../generic_utils';

// -------------------------------------------------------------------------------------------------------

export function draw_bp_timeline_chart(bto, measurement_data) {
  /** helper function to draw a bp timeline chart in the BpTimelineChart element's svg */

  // get svg
  let svg = d3.select(bto.svg_ref.current);

  // get markers
  let markers = svg.select('g#bp_markers').selectAll('#marker');

  // settings
  let marker_radius = bto.x_scale.current(4);
  let timeline_margin = bto.x_scale.current(10);

  // function to determine shift of each marker
  function determine_timeline_shift(measurement_data, idx) {
    let shift = marker_radius + timeline_margin;
    let ctr = measurement_data.length - 1;
    while (ctr > idx) {
      shift += marker_radius * 2;
      let diff =
        measurement_data[ctr].created.at.date_object.getTime() -
        measurement_data[ctr - 1].created.at.date_object.getTime();
      diff = Math.log(diff / 10) / 2;
      diff = diff < 0 ? 0 : bto.x_scale.current(diff);
      shift += diff;
      ctr--;
    }
    return shift;
  }

  // set extended height for enumerated view
  let height = bto.y_scale.current(90);
  let width = determine_timeline_shift(measurement_data, 0) + marker_radius + timeline_margin;
  svg.attr('height', `${height}px`).attr('width', `${width}px`);

  // set markers location and radius for later drawing
  markers
    .attr('y', function (d, i) {
      return bto.y_scale.current(60);
    })
    .attr('x', function (d, i) {
      return determine_timeline_shift(measurement_data, i);
    })
    .attr('r', marker_radius); // radius of not-expanded marker

  // include text to label date
  markers
    .append('text')
    .attr('id', 'timeline_date_text')
    .text(function (d) {
      return get_date_string(d.created.at.date_object);
    })
    .attr('dy', '0em')
    .attr('transform', function (d) {
      let X = d3.select(this.parentNode).attr('x');
      let Y = parseFloat(d3.select(this.parentNode).attr('y')) - marker_radius - bto.y_scale.current(2);
      return `translate(${X},${Y}) rotate(-45)`;
    });
}
