// d3
import * as d3 from 'd3';
// zach
import { get_date_string } from '../generic_utils';

// -------------------------------------------------------------------------------------------------------

export function draw_bp_enumerate_chart(bto, measurement_data) {
  /** helper function to draw a bp enumerate chart in the BpTimelineChart element's svg */

  // get svg
  let svg = d3.select(bto.svg_ref.current);

  // set extended height for enumerated view
  svg
    .attr('height', `${bto.y_scale.current(bto.enum_sizes.total_height)}px`)
    .attr('width', `${bto.x_scale.current(90)}px`);

  // get markers
  let markers = svg.select('g#bp_markers').selectAll('#marker');

  // set markers location and radius for later drawing
  markers
    .attr('y', function (d, i) {
      let idx = measurement_data.length - i - 1;
      return bto.y_scale.current(bto.enum_sizes.pad + idx * (2 * bto.enum_sizes.radius + bto.enum_sizes.separation));
    })
    .attr('x', function (d) {
      return bto.x_scale.current(25);
    })
    .attr('r', bto.y_scale.current(bto.enum_sizes.radius)); // radius of not-expanded marker

  // add date and id text next to each enummerated marker
  markers
    .append('text')
    .attr('id', 'enumerate_text_details')
    .attr('x', function (d) {
      return `${bto.x_scale.current(50)}px`;
    })
    .attr('y', function (d) {
      return `${d3.select(this.parentNode).attr('y')}px`;
    })
    .text(function (d) {
      return get_date_string(d.created.at.date_object);
    })
    .attr('dy', '-0.5em');
  markers
    .append('text')
    .attr('id', 'enumerate_text_details')
    .attr('x', function (d) {
      return `${bto.x_scale.current(50)}px`;
    })
    .attr('y', function (d) {
      return `${d3.select(this.parentNode).attr('y')}px`;
    })
    .attr('dy', '0.5em')
    .text(function (d) {
      return d.id;
    });
  // comment text
  markers
    .append('text')
    .attr('id', 'comment_text')
    .text(function (d) {
      return d.comment;
    })
    .attr('x', function (d) {
      return `${bto.x_scale.current(50)}px`;
    })
    .attr('y', function (d) {
      return `${d3.select(this.parentNode).attr('y')}px`;
    })
    .attr('dy', '1.5em')
    .style('fill', function (d) {
      return d.tags.review_flag ? 'red' : 'blue';
    });
}
