import React, { useEffect, useState, useContext } from 'react';
// auth0
import { useAuth0 } from '@auth0/auth0-react';
// icons
import { Icon } from '@iconify/react';
// mui
import { Box, Drawer, Button } from '@mui/material';
// zach
import { SnackMessageContext } from '../App.js';
import { SX } from './utils/sx_styling';

// -------------------------------------------------------------------------------------------------------

export default function InfoDrawer({ info_open, set_info_open }) {
  /** Drawer to show info about Riva Diva, including version and JWT */

  // auth0
  let { getAccessTokenSilently } = useAuth0();

  // use state to keep track of jwt token from getAccessTokenSilently promise
  let [token, set_token] = useState('');

  // get jwt token upon render
  useEffect(() => {
    getAccessTokenSilently().then((result) => {
      set_token(result);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // snack message to indicate something was copied
  let display_snackbar_message = useContext(SnackMessageContext);

  // button to copy git commit
  let git_copy_button = (
    <Box sx={SX('sub_section', 'm3', 'flex')}>
      <Button
        onClick={() => {
          navigator.clipboard.writeText(process.env.REACT_APP_GIT_COMMIT);
          display_snackbar_message('GIT Commit copied to clipboard');
        }}
      >
        <Box component={Icon} icon={'akar-icons:copy'} sx={SX('color_black', { width: '80%', height: '80%' })} />
      </Button>
    </Box>
  );
  // box to hold git commit
  let git_box = (
    <Box
      sx={SX('border1_gray', 'color_gray', 'sub_section', 'm3', 'flex', 'center_items', {
        width: '80vw',
        wordWrap: 'break-word',
        fontSize: '16px',
        padding: '5px',
      })}
    >
      {process.env.REACT_APP_GIT_COMMIT}
    </Box>
  );
  // button and box for git commit
  let git_commit_display = (
    <Box sx={SX('flex', 'column', 'center_items')}>
      <Box>{'GIT Commit:'}</Box>
      <Box sx={SX('flex')}>
        {git_copy_button}
        {git_box}
      </Box>
    </Box>
  );

  // button to show copy icon and copy jwt
  let jwt_copy_button = (
    <Box sx={SX('sub_section', 'm3', 'flex')}>
      <Button
        onClick={() => {
          navigator.clipboard.writeText(token);
          display_snackbar_message('JWT copied to clipboard');
        }}
      >
        <Box component={Icon} icon={'akar-icons:copy'} sx={SX('color_black', { width: '80%', height: '80%' })} />
      </Button>
    </Box>
  );
  // box to hold and show jwt token
  let jwt_box = (
    <Box
      sx={SX('border1_gray', 'color_gray', 'sub_section', 'm3', {
        width: '80vw',
        wordWrap: 'break-word',
        fontSize: '10px',
        padding: '5px',
      })}
    >
      <Box sx={SX({ fontSize: '10px' })}>{token}</Box>
    </Box>
  );
  // button and box for jwt
  let jwt_token_display = (
    <Box sx={SX('flex', 'column', 'center_items')}>
      <Box>{'JWT:'}</Box>
      <Box sx={SX('flex')}>
        {jwt_copy_button}
        {jwt_box}
      </Box>
    </Box>
  );

  return (
    <Drawer
      anchor={'bottom'}
      open={info_open}
      onClose={() => {
        set_info_open(false);
      }}
    >
      <Box sx={SX({ height: '50vh' }, 'flex', 'column', 'center', 'center_items', 'border1')}>
        <Box sx={SX('flex')}>
          Riva&nbsp;<Box sx={SX('color_red')}>DIVA</Box>
        </Box>
        <Box sx={SX('flex')}>
          (<Box sx={SX('color_red')}>D</Box>
          ata&nbsp;
          <Box sx={SX('color_red')}>I</Box>
          nspection&nbsp;
          <Box sx={SX('color_red')}>V</Box>
          isualization&nbsp;and&nbsp;
          <Box sx={SX('color_red')}>A</Box>
          nalysis&nbsp;toolset)
        </Box>
        <br />
        <Box component="img" src="/static/logo.svg" sx={SX({ height: '5vh' }, 'flex')} />
        <br />
        <br />
        {git_commit_display}
        <br />
        {jwt_token_display}
      </Box>
    </Drawer>
  );
}
