import React, { useState } from 'react';
// mui
import { Box, Button } from '@mui/material';
// zach
import { SX } from './utils/sx_styling';

// -------------------------------------------------------------------------------------------------------

export default function TestingGrounds() {
  let [my_state, set_my_state] = useState('On');

  // env vars accessed here:
  // console.log('process.env', process.env)

  let toggle_button = (
    <Button
      onClick={() => {
        set_my_state(my_state === 'On' ? 'Off' : 'On');
      }}
    >
      {my_state}
    </Button>
  );
  return (
    <Box sx={SX('flex', 'column', 'center_items', 'm25')}>
      {'Testing Grounds'}
      {toggle_button}
    </Box>
  );
}
