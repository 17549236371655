import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
//other
import ReactJson from 'react-json-view';
// icons
import { Icon } from '@iconify/react';
// mui
import {
  Box,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
  Button,
  Collapse,
} from '@mui/material';
// auth0
import { useAuth0 } from '@auth0/auth0-react';
// zach
import {
  useMeasurementRawDataPpgHook,
  useMeasurementRawDataDiagnosticImagesHook,
  useMeasurementCalibrationDataHook,
  backendWriteComment,
} from './utils/hooks';
import RawPpgPlot from './RawPpgPlot';
import DiagnosticImageView from './DiagnosticImageView';
import { loading_view, download_json_button, get_date_string } from './utils/generic_utils';
import ShareDivaLinkBox from './ShareDivaLinkBox';
import { SnackMessageContext } from '../App';
import { SX } from './utils/sx_styling';

// -------------------------------------------------------------------------------------------------------

export default function MeasurementView({
  measurement_id, // string; id of measurment to show
  this_measurement_data, // excerpt from measurment_data for this measurement
  user_id, // id of user's measurements being viewed
  mutate_data, // method to refresh data loaded from hooks
  lock_views, // views for locked view in UserCard
  set_lock_views, // used to set the locked view in the UserCard
}) {
  /** displays raw ppg data and corresponding json of selected measurement (unless it's a cuff measurement) */

  // bool to track if view has raw ppg data or not
  let is_cuff = this_measurement_data.type === 'cuff';

  let { getAccessTokenSilently } = useAuth0();

  // load_data_err: bool; flag used to indicate that the content is having trouble loading
  let [load_data_err, set_load_data_err] = useState(false)

  // busy: bool; flag used to indicate that content is being loaded
  let [busy, set_busy] = useState(false);

  let { raw_ppg_data, raw_ppg_data_error } = useMeasurementRawDataPpgHook(this_measurement_data, measurement_id);

  let { diagnostic_images } = useMeasurementRawDataDiagnosticImagesHook(this_measurement_data, measurement_id);

  let { calibration_data } = useMeasurementCalibrationDataHook(this_measurement_data.calibrationId);

  // state to show/hide ppg plot, diagnostic images, cloudwatch, or json
  let [mode_view, set_mode_view] = useState(''); // {'plot', 'images', 'json'}
  // state to keep track of comment typed by user
  let [text_edit_comment, set_text_edit_comment] = useState('');

  // refs for panels to show sensor data
  let ppg_collapse_ref = useRef();
  let images_collapse_ref = useRef();
  let json_collapse_ref = useRef();

  useEffect(() => {
    console.log('this_measurement_data', this_measurement_data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    console.log('diagnostic_images', diagnostic_images)
  }, [diagnostic_images])

  // make measurement view show loading while raw data is fetched
  useEffect(() => {
    if (!is_cuff) {
      if (!raw_ppg_data) {
        set_busy(true);
      } else {
        set_busy(false);
        console.log('raw_ppg_data loaded:', raw_ppg_data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [raw_ppg_data]);

  // make measurement view show error if useMeasurementRawDataPpgHook errors
  useEffect(() => {
    if (raw_ppg_data_error !== undefined){
      set_load_data_err(true)
    } else {
      set_load_data_err(false)
    }
  }, [raw_ppg_data_error])

  // snack message to indicate comment was saved
  let display_snackbar_message = useContext(SnackMessageContext);

  // provides a table with an overview of the measurment
  function generate_measurement_view_header() {
    // button to split calibration off
    let split_calibration_data_button = calibration_data ? (
      <Button
        onClick={() => {
          set_lock_views(calibration_data);
        }}
        disabled={lock_views.length !== 0}
        sx={SX('button', 'm10', { marginBottom: '0px' })}
      >
        SPLIT CALIBRATION
      </Button>
    ) : null;
    let split_calibration_data_box = (
      <Box sx={SX({ height: '5vh' }, 'flex', 'center')}>{split_calibration_data_button}</Box>
    );

    let bp = `${Math.round(this_measurement_data.systolicBloodPressure)}
       / ${Math.round(this_measurement_data.diastolicBloodPressure)}`;
    let full_date_time_string = `${get_date_string(this_measurement_data.created.at.date_object)}
       (${this_measurement_data.created.at.string})`;
    let table = (
      <Box sx={SX('m10')}>
        <TableContainer component={Paper}>
          <Table sx={SX('riva_background_2')} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell>Meas. ID</TableCell>
                <TableCell align="right">{this_measurement_data.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>DateTime Acq.</TableCell>
                <TableCell align="right">{full_date_time_string}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell align="right">{this_measurement_data.type}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>BP</TableCell>
                <TableCell align="right">{bp}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );

    // include found tags in magenta
    let found_tags = '';
    for (let key of Object.keys(this_measurement_data.tags)) {
      if (this_measurement_data.tags[key] === true) {
        found_tags += key + ', ';
      }
    }
    let tags_element_text = 'No Tags Found';
    let font_color = 'color_black';
    if (found_tags !== '') {
      tags_element_text = 'Tags Found:';
      font_color = 'color_magenta';
      found_tags = found_tags.slice(0, -2);
    }
    let tags_element = (
      <Box
        sx={SX('m10', 'sub_section', 'flex', 'column', font_color, 'center_items', { height: '5vh', overflow: 'auto' })}
      >
        <Box sx={SX('m3', { fontWeight: 'bold' })}>{tags_element_text}</Box>
        {found_tags}
      </Box>
    );

    // s3Location element
    let s3_element = (
      <Box sx={SX('sub_section', 'm10', 'flex', 'column')}>
        <Box sx={SX('m10', { overflow: 'auto', whiteSpace: 'nowrap' })}>
          {this_measurement_data.s3Location}
        </Box>
        {this_measurement_data.type !== "NO_ESTIMATION" ?
          <Box sx={SX('m10')}>
            {`(s3 source bucket is actually the "copy" version)`}
          </Box> :
          null
        }
      </Box>
    );

    // comment elements
    let comment_text_field = (
      <TextField
        id="filled-basic"
        variant="filled"
        value={text_edit_comment}
        onChange={(event) => {
          set_text_edit_comment(event.target.value);
        }}
        sx={SX({ width: '100%' }, 'm10')}
      />
    );
    let save_comment_button = (
      <Button
        onClick={() => {
          backendWriteComment(user_id, measurement_id, text_edit_comment, getAccessTokenSilently);
          mutate_data();
          setTimeout(() => {
            mutate_data();
          }, 3000); // refresh comments again after 3 seconds
          display_snackbar_message('comment written to backend');
        }}
        sx={SX('button', 'm3')}
      >
        Save
      </Button>
    );
    let comment_display = <Box sx={SX('m10', { overflow: 'auto' })}>{this_measurement_data.comment}</Box>;
    let comment_element = (
      <Box sx={SX('sub_section', 'm10', 'flex', 'column')}>
        {comment_display}
        <Box sx={SX('flex', 'center')}>
          {comment_text_field}
          {save_comment_button}
        </Box>
      </Box>
    );

    // divider
    let divider = (
      <Box
        sx={SX('m10', 'curve_border', {
          border: '4px solid rgb(150, 150, 150)',
          marginTop: '30px',
          marginBottom: '10px',
        })}
      />
    );

    // return
    return (
      <Box>
        {split_calibration_data_box}
        {table}
        <ShareDivaLinkBox individual_id={this_measurement_data.userId} measurement_id={measurement_id} />
        {s3_element}
        {comment_element}
        {tags_element}
        {divider}
      </Box>
    );
  }

  // view to show user that there was an error loading
  let load_data_err_view = (
    <Box sx={SX('flex', 'column', 'center', 'm10')}>
      <Box sx={SX('flex', 'center')}>{'Trouble loading raw data'}</Box>
      <br />
      <Box sx={SX('flex', 'center')}>{'\uD83D\uDC94'}</Box>
      <br />
      <Box sx={SX('flex', 'center', 'color_gray')}>{raw_ppg_data_error ? raw_ppg_data_error.toString() : "raw_ppg_data_error undefined"}</Box>
      <br />
    </Box>
  )

  // make loading display if busy
  if (load_data_err) {
    return load_data_err_view
  } else if (busy) {
    return loading_view('Loading raw data...');
  } else if (is_cuff) {
    return generate_measurement_view_header();
  } else {
    // visualize raw data json
    let json_view = raw_ppg_data ? (
      <Box sx={SX('sub_section', 'm10')}>
        <ReactJson
          src={raw_ppg_data}
          shouldCollapse={(field) => {
            return [].concat(Object.keys(raw_ppg_data)).includes(field.name);
          }}
        />
      </Box>
    ) : null;

    // download raw data json
    let download_button = raw_ppg_data
      ? download_json_button(raw_ppg_data, `raw_ppg_data__${measurement_id}`, 'DOWNLOAD raw_ppg_data', [
          'button',
          'm10',
        ])
      : null;

    // link to cloudwatch and DataDog
    let link_to_cloudwatch = null;
    let link_to_datadog = null;
    if (raw_ppg_data) {
      let bpEstimationResponses = raw_ppg_data.bpEstimationResponses;
      if (bpEstimationResponses) {
        if (bpEstimationResponses.length > 0) {
          let body_from_log = bpEstimationResponses
            ? bpEstimationResponses[bpEstimationResponses.length - 1].log
            : null;
          if (body_from_log) {
            // cloudwatch
            link_to_cloudwatch =
              'https://us-west-2.console.aws.amazon.com/cloudwatch/home?region=us-west-2#logsV2:log-groups/log-group/';
            link_to_cloudwatch += body_from_log.groupName.replaceAll('/', '$252F');
            link_to_cloudwatch += '/log-events/';
            link_to_cloudwatch += body_from_log.streamName.replaceAll('/', '$252F');
            // datadog
            link_to_datadog = 'https://riva-nonprod.datadoghq.com/logs?query=%40lambda.request_id%3A'
            link_to_datadog += body_from_log.requestId
            link_to_datadog += '&cols=host%2Cservice&index=&messageDisplay=inline&stream_sort=time%2Casc&viz=stream&from_ts='
            let timestamps_field = bpEstimationResponses[bpEstimationResponses.length - 1].estimate ?
              bpEstimationResponses[bpEstimationResponses.length - 1].estimate.timestamps :
              bpEstimationResponses[bpEstimationResponses.length - 1].timestamps
            let last_window =
              timestamps_field.window_3 ? timestamps_field.window_3 :
              timestamps_field.window_2 ? timestamps_field.window_2 :
              timestamps_field.window_1;
            link_to_datadog += last_window.start
            link_to_datadog += '&to_ts='
            link_to_datadog += last_window.end
            link_to_datadog += '&live=true'
          }
        }
      }
    }

    // toggle panels
    let panel_info = [
      {
        name: 'PPG PLOT',
        icon: 'carbon:chart-multi-line',
        on_click: () => {
          set_mode_view('plot');
        },
      },
      {
        name: 'IMAGES',
        icon: 'akar-icons:image',
        on_click: () => {
          set_mode_view('images');
        },
      },
      { name: 'CLOUDWATCH', icon: 'akar-icons:cloud', link_to_url: link_to_cloudwatch },
      { name: 'DATADOG', icon: 'simple-icons:datadog', link_to_url: link_to_datadog },
      {
        name: 'PPG JSON',
        icon: 'mdi:code-json',
        on_click: () => {
          set_mode_view('json');
        },
      },
    ];
    let panels = [];
    panel_info.forEach((info, idx) => {
      panels.push(
        <Button
          key={idx}
          sx={SX('button', 'flex', 'column', { height: '20vh', width: '10vw' }, 'm10')}
          href={info.link_to_url}
          target="_blank"
          rel="noreferrer"
          disabled={!info.link_to_url && !info.on_click}
          onClick={info.on_click}
        >
          <Box sx={SX({ fontSize: '1em', fontWeight: 'bold' }, 'm3')}>{info.name}</Box>
          <Box component={Icon} icon={info.icon} sx={SX('color_black', { width: '100%', height: '100%' }, 'm10')} />
        </Button>
      );
    });
    let toggle_panels = <Box sx={SX('flex', 'center')}>{panels}</Box>;

    // back button
    let back_button = (
      <Button
        onClick={() => {
          set_mode_view('');
        }}
        sx={SX('button', 'm10')}
      >
        <Box
          component={Icon}
          icon={'akar-icons:arrow-left-thick'}
          sx={SX('color_gray', { width: '100%', height: '100%' }, 'm10')}
        />
      </Button>
    );

    // panel collapse
    let panel_collapse = <Collapse in={mode_view === ''}>{toggle_panels}</Collapse>;

    // get collapses
    let collapses = [];
    let collapse_info = [
      {
        mode: 'plot',
        ref: ppg_collapse_ref,
        content: <RawPpgPlot raw_ppg_data={raw_ppg_data} />,
      },
      {
        mode: 'images',
        ref: images_collapse_ref,
        content: <DiagnosticImageView diagnostic_images={diagnostic_images} measurement_id={measurement_id} />,
      },
      {
        mode: 'json',
        ref: json_collapse_ref,
        content: (
          <Box sx={SX('flex', 'column')}>
            {json_view}
            {download_button}
          </Box>
        ),
      },
    ];
    collapse_info.forEach((info, idx) => {
      collapses.push(
        <Collapse
          key={idx}
          in={mode_view === info.mode}
          ref={info.ref}
          onEntered={() => {
            info.ref.current.scrollIntoView({
              behavior: 'smooth',
            });
          }}
        >
          {back_button}
          {info.content}
        </Collapse>
      );
    });

    // return
    return (
      <Box sx={SX('flex', 'column')}>
        {generate_measurement_view_header()}
        {panel_collapse}
        {collapses}
      </Box>
    );
  }
}

MeasurementView.propTypes = {
  measurement_id: PropTypes.string.isRequired, // string; id of measurment to show
};
