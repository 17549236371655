import { Navigate, useRoutes } from 'react-router-dom';
import Page from './zach/Page';
import BloodPressureDashboard from './zach/BloodPressureDashboard';
import JupyterVisualizer from './zach/JupyterVisualizer';
import TestingGrounds from './zach/TestingGrounds';

// ----------------------------------------------------------------------

/** routes the user based on the url path */
export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <Page content={<BloodPressureDashboard />} />,
    },
    {
      path: '/dashboard/:auto_string',
      element: <Page content={<BloodPressureDashboard />} />,
    },
    {
      path: '/jupytervisualizer',
      element: <Page content={<JupyterVisualizer />} />,
    },
    {
      path: '/testinggrounds',
      element: <Page content={<TestingGrounds />} />,
    },
    { path: '*', element: <Navigate to="/dashboard" /> },
  ]);
}
