import useSWR from 'swr';
// auth0
import { useAuth0 } from '@auth0/auth0-react';
// zach
import { clean_measurement_data } from './generic_utils';

// -------------------------------------------------------------------------------------------------------

// backend url
let BACKEND_URL;
if (process.env.REACT_APP_INSTANCE === 'dev') {
  BACKEND_URL = `https://o5jpbqxp41.execute-api.us-west-2.amazonaws.com/api`;
} else if (process.env.REACT_APP_INSTANCE === 'stage') {
  BACKEND_URL = `https://vevatxg3z1.execute-api.us-west-2.amazonaws.com/api`;
} else if (process.env.REACT_APP_INSTANCE === 'demo') {
  BACKEND_URL = `https://0p02y6ad5f.execute-api.us-west-2.amazonaws.com/api`;
}

export function useUserDataHook() {
  /** hook to return a list of users in DIVA's current environment */

  let { getAccessTokenSilently } = useAuth0();
  let limit = 99;
  let url = `${process.env.REACT_APP_API_BASE_URL}/v1/user?limit=${limit}`; // &page=${page}
  let fetcher = async (url) => {
    let users = [];
    let page = 1;
    let total_pages = 1;
    while (page <= total_pages) {
      let url_page = url + `&page=${page}`;
      let token = await getAccessTokenSilently();
      let response = await fetch_helper(url_page, token);
      let json = await response.json();
      users = users.concat(json.users);
      total_pages = json.meta.totalPages;
      page++;
    }
    return users;
  };
  let { data, error, isValidating, mutate } = useSWR(url, fetcher);

  return {
    user_data: data,
    user_data_error: error,
    user_data_measurement_data: isValidating,
    mutate_user_data: mutate,
  };
}

export function useMeasurementDataHook({ user_id, start_date, end_date }) {
  /** hook to return measurement_data for a provided user_id */

  // must have some sort of argument
  if (!user_id && !start_date && !end_date) {
    throw new Error('No arguments found for useMeasurementDataHook');
  }
  let { getAccessTokenSilently } = useAuth0();
  let url = `${process.env.REACT_APP_API_BASE_URL}/v1/blood-pressure-reading?`;
  if (user_id) {
    url += `userId=${user_id}&`;
  }
  if (start_date) {
    url += `startDate=${start_date}&`;
  }
  if (end_date) {
    url += `endDate=${end_date}&`;
  }

  let fetcher = async (url) => {
    let token = await getAccessTokenSilently();
    let response = await fetch_helper(url, token);
    let array = await response.json();
    if (array == null) {
      throw new Error('No measurements found');
    }
    return clean_measurement_data(array);
  };
  let { data, error, isValidating, mutate } = useSWR(url, fetcher);
  return {
    measurement_data: data,
    measurement_data_error: error,
    validating_measurement_data: isValidating,
    mutate_measurment_data: mutate,
  };
}

export function useMeasurementCalibrationDataHook(calibration_id) {
  /** hook to return measurement_data for a calibration_id */

  let url = calibration_id
    ? `${process.env.REACT_APP_API_BASE_URL}/v1/calibration/${calibration_id}/blood-pressure-reading`
    : null;
  let { getAccessTokenSilently } = useAuth0();

  let fetcher = async (url) => {
    let token = await getAccessTokenSilently();
    let response = await fetch_helper(url, token);
    let json = await response.json();
    return clean_measurement_data(json);
  };
  let { data, error, isValidating, mutate } = useSWR(url, fetcher);

  return {
    calibration_data: data,
    calibration_data_error: error,
    validating_calibration_data: isValidating,
    mutate_calibration_data: mutate,
  };
}

export function useMeasurementRawDataPpgHook(this_measurement_data, measurement_id) {
  /** hook to return the raw ppg data for a given user_id's measurement_id (returns nothing if is_cuff is true) */

  // JWT
  let { getAccessTokenSilently } = useAuth0();

  // url and fetcher
  let url;
  let fetcher;
  if (this_measurement_data.type === 'cuff') {
    // don't query for cuff measurement data; there is no data
    url = null;
  } else if (this_measurement_data.type === 'NO_ESTIMATION') {
    // query diva backend for noEstimation measurement data
    url = `${BACKEND_URL}/fetch_noEstimation`;
    let body = JSON.stringify({
      bucket: this_measurement_data.noEstimation_s3_bucket,
      key: this_measurement_data.noEstimation_s3_key,
    });
    fetcher = async (url) => {
      let token = await getAccessTokenSilently();
      let headers = new Headers();
      headers.append('Authorization', `Bearer ${token}`);
      headers.append('Content-Type', 'application/json');
      let fetched = await fetch(url, { method: 'POST', headers: headers, body: body });
      let response = await fetched.json();
      return response;
    };
  } else {
    // regular api query
    url = `${process.env.REACT_APP_API_BASE_URL}/v1/user/${this_measurement_data.userId}/blood-pressure-reading/${measurement_id}/ppg`;
    fetcher = async (url) => {
      let token = await getAccessTokenSilently();
      let response = await (await fetch_helper(url, token)).json();
      if (response.rawPPG.signedUrl === '') {
        throw new Error('Found empty signedUrl');
      }
      let raw_data = await (await fetch(response.rawPPG.signedUrl, { method: 'GET' })).json();
      return raw_data;
    };
  }

  // SWR
  let { data, error, isValidating, mutate } = useSWR(url, fetcher);
  return {
    raw_ppg_data: data,
    raw_ppg_data_error: error,
    validating_raw_ppg_data: isValidating,
    mutate_raw_ppg_data: mutate,
  };
}

export function useMeasurementRawDataDiagnosticImagesHook(this_measurement_data, measurement_id) {
  /** hook to return the diagnostic images for a given user_id's measurement_id (returns nothing if is_cuff is true) */

  // JWT
  let { getAccessTokenSilently } = useAuth0();

  // url and fetcher
  let url;
  let fetcher;
  if (this_measurement_data.type === 'cuff') {
    // don't query for cuff measurement data; there is no data
    url = null;
  } else {
    // query diva backend for noEstimation measurement data
    url = `${BACKEND_URL}/fetch_noEstimation_images_signed_urls`;
    let body;
    if (this_measurement_data.type === 'NO_ESTIMATION') {
      body = JSON.stringify({
        prefix: this_measurement_data.noEstimation_s3_key.split('__rawPPG.json')[0],
      });
    } else {
      // s3://riva-artifacts-bp-dev/blood-pressure-ppg/ios/...
      body = JSON.stringify({
        prefix: this_measurement_data.s3Location.match('s3://.*?/(.*)')[1].split('__rawPPG.json')[0],
      });
    }
    fetcher = async (url) => {
      let token = await getAccessTokenSilently();
      let headers = new Headers();
      headers.append('Authorization', `Bearer ${token}`);
      headers.append('Content-Type', 'application/json');
      let fetched = await fetch(url, { method: 'POST', headers: headers, body: body });
      let response = await fetched.json();
      let images = [];
      let idx = 0;
      response.sort((a, b) => {
        let fna = a.key ? a.key.split('__').slice(-1)[0].split(".")[0] : "";
        let fnb = b.key ? b.key.split('__').slice(-1)[0].split(".")[0] : "";
        // console.log('fna', fna)
        let numa = -Infinity
        let numb = -Infinity
        if (fna.includes('fit_plot') || fna.includes('diagnostic_plot')) {
          numa = Number(fna.split('_plot').slice(-1)[0])
        }
        if (fnb.includes('fit_plot') || fnb.includes('diagnostic_plot')) {
          numb = Number(fnb.split('_plot').slice(-1)[0])
        }
        return numa - numb;
      })
      // skip p files
      for (let item of response) {
        if (item.key.split('.').slice(-1)[0] === 'p'){
          continue
        }
        let image_promise = fetch(item.signed_url);
        let image_ob = {
          file_name: item.key,
          idx: idx++,
          image_promise: image_promise,
        };
        images.push(image_ob);
      }
      return images;
    };
  }

  let { data, error } = useSWR(url, fetcher);
  return {
    diagnostic_images: data,
    diagnostic_images_error: error,
  };
}

export function useBackendReadNoEstimationsHook(user_id) {
  /** read all noEstimation files for a specified user_id */

  let url = BACKEND_URL + `/read_noEstimations`;
  let { getAccessTokenSilently } = useAuth0();

  let body = JSON.stringify({
    user_id: user_id,
  });
  let fetcher = async (url) => {
    let token = await getAccessTokenSilently();

    let headers = get_myHeaders(token);
    let requestOptions = {
      method: 'POST',
      headers: headers,
      body: body,
      redirect: 'follow',
    };
    let response = await fetch(url, requestOptions);
    let json = await response.json();
    return json;
  };

  let { data, error, isValidating, mutate } = useSWR(url, fetcher);

  if (data && data.errors && data.errors.auth) {
    data = undefined;
  }

  return {
    noEstimation_data: data,
    noEstimation_data_error: error,
    validating_noEstimation_data: isValidating,
    mutate_noEstimation_data: mutate,
  };
}

export function useBackendReadCommentsHook(user_id) {
  /** read all the comments for a specified user_id */

  let url = BACKEND_URL + `/read_comments/${user_id}`;
  let { getAccessTokenSilently } = useAuth0();

  let fetcher = async (url) => {
    let token = await getAccessTokenSilently();
    let headers = get_myHeaders(token);
    let requestOptions = {
      method: 'GET',
      headers: headers,
      redirect: 'follow',
    };
    let response = await fetch(url, requestOptions);
    let json = await response.json();
    return json;
  };

  let { data, error, isValidating, mutate } = useSWR(url, fetcher);

  if (data && data.errors && data.errors.auth) {
    data = undefined;
  }

  return {
    comments_data: data,
    comments_data_error: error,
    validating_comments_data: isValidating,
    mutate_comments_data: mutate,
  };
}

export function backendWriteComment(user_id, measurement_id, comment, getAccessTokenSilently) {
  /** writes a comment to the backend for a specific user_id and measurement_id -
   * Must be provided with getAccessTokenSilently method since this isn't a tranditional hook
   */

  let url = BACKEND_URL + `/write_comment`;

  getAccessTokenSilently().then((token) => {
    let body = JSON.stringify({
      user_id: user_id,
      measurement_id: measurement_id,
      comment: comment,
    });
    let headers = get_myHeaders(token);
    let requestOptions = {
      method: 'POST',
      headers: headers,
      body: body,
      redirect: 'follow',
    };
    fetch(url, requestOptions)
      .then((response) => {
        console.log('successfully posted comment', response);
      })
      .catch((error) => {
        console.log('there was an issue posting the comment', error);
      });
  });
}

function fetch_helper(url, token, disable_header = false, method = 'GET', body = undefined) {
  /** generates headers for riva-api requests and returns a promise object that yields a json-like
   * object of the result
   */

  let headers = { Authorization: `Bearer ${token}`, 'x-csrf-token': 'true' };
  if (disable_header) {
    return fetch(url);
  } else {
    return fetch(url, { method: method, headers: headers, body: body });
  }
}

function get_myHeaders(token) {
  /** helper function to get headers for fetch calls */

  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');
  return myHeaders;
}
