import React, { useEffect, useState, useRef, createContext } from 'react';
import PropTypes from 'prop-types';
// mui
import { Box, Button } from '@mui/material';
// zach
import { useMeasurementDataHook, useBackendReadCommentsHook, useBackendReadNoEstimationsHook } from './utils/hooks';
import { loading_view, clean_measurement_data } from './utils/generic_utils';
import TabMeasurementsView from './TabMeasurementsView';
import { SX } from './utils/sx_styling';

// -------------------------------------------------------------------------------------------------------

export default function UserCard({
  user_id, // user_id: id corresponding to the user that this card will display information for
  auto_string, // string to automatically open measurment
  show_identifying_info, // bool; if true shows identifying info (user name)
  user_name, // name of user
  set_title_text, // ContentCard
  set_status_message, // ContentCard
  set_busy, // ContentCard
  good_card, // ContentCard
  set_good_card, // ContentCard
  set_expand, // ContentCard
}) {
  /** UserCard displays all information regarding a single user. This includes measurmeents,
   * and raw data; depending on user interaction. Upon initial render the card loads information on all
   * measurements, but does not load raw ppg or diagnostic data. This information is contained
   * within tabs on the UserCard and primarily conveyed via a timeline. When the user selects a set
   * or measurement then the raw data for that(those) measurement(s) is downloaded and displayed under
   * a new tab.
   */

  // measurement_data: array of objects; one for each measurement
  let { measurement_data, measurement_data_error, mutate_measurment_data } = useMeasurementDataHook({
    user_id: user_id,
  });

  // comments data
  let { comments_data, mutate_comments_data } = useBackendReadCommentsHook(user_id);

  // noEstimation data
  let { noEstimation_data, mutate_noEstimation_data } = useBackendReadNoEstimationsHook(user_id);

  // function to mutate all data
  let mutate_data = () => {
    mutate_measurment_data();
    mutate_comments_data();
    mutate_noEstimation_data();
  };

  // sets width of tabs display and locked measurement view
  let [lock_split, set_lock_split] = useState(0.5);
  // extra set of views for when the UserCard is split so two TabMeasurementView elements may be shown side-by-side
  let [lock_views, set_lock_views] = useState([]);

  // set divider width
  let divider_width = 0.01;

  // ref for box containing content
  let content_box_ref = useRef();

  // height for tabs
  let tab_height = '7vh';

  // set up card upon mounting
  useEffect(() => {
    console.log(`opening card ${user_id}`);
    set_title_text(user_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle measurment_data loading
  useEffect(() => {
    if (measurement_data_error) {
      set_status_message(measurement_data_error.toString());
      set_busy(false);
      set_good_card(false);
    } else if (!measurement_data) {
      set_status_message('Loading measurements...');
      set_busy(true);
    } else {
      set_status_message(show_identifying_info ? user_name : user_id);
      set_good_card(true);
      set_expand(true);
      set_busy(false);
      console.log('measurement_data loaded:', measurement_data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measurement_data, measurement_data_error, show_identifying_info]);

  // return loading view if no measurment_data
  if (!measurement_data) {
    if (good_card) {
      return loading_view();
    } else {
      return <Box sx={SX('flex', 'center')}>{'\uD83D\uDC94'}</Box>;
    }
  }

  // get cleaned_measurement_data
  let cleaned_measurement_data = clean_measurement_data(measurement_data, comments_data, noEstimation_data, user_id);

  // handle case where there are zero measurements for a user
  if (cleaned_measurement_data.length === 0) {
    return <Box sx={SX('flex', 'center')}>{'No measurements yet \uD83D\uDC94'}</Box>; // broken heart: \uD83D\uDC94
  }

  // lock set view
  let lock_view;
  let lock_divider;
  if (lock_views.length > 0) {
    lock_view = (
      <Box sx={SX({ width: 1 - lock_split - divider_width / 2 })}>
        <Box sx={SX({ height: tab_height }, 'flex', 'center', 'center_items')}>
          <Button
            onClick={() => {
              set_lock_views([]);
            }}
            sx={SX({ width: 0.5, height: 0.7 }, 'button', 'm10')}
          >
            <Box component="img" src="/static/close_icon.svg" sx={SX('tab_close', { width: 1.0, height: 1.0 })} />
          </Button>
        </Box>
        <TabMeasurementsView
          measurement_data={lock_views}
          mutate_data={mutate_data}
          use_timeline={false}
          lock_views={lock_views}
          set_lock_views={set_lock_views}
          user_id={user_id}
          key={user_id + 'lock'}
        />
      </Box>
    );

    // slidable divider
    // callbacks
    let divider_mouseup_callback = (event) => {
      window.removeEventListener('mouseup', divider_mouseup_callback);
      window.removeEventListener('mousemove', divider_mousemove_callback);
    };
    let divider_mousedown_callback = (event) => {
      window.addEventListener('mouseup', divider_mouseup_callback);
      window.addEventListener('mousemove', divider_mousemove_callback);
    };
    let divider_mousemove_callback = (event) => {
      // get ratio
      let rect = content_box_ref.current.getBoundingClientRect();
      let ratio = 1 - (event.clientX - rect.left) / (rect.right - rect.left);
      // min and max ratios
      if (ratio < 0.1) {
        ratio = 0.1;
      }
      if (ratio > 0.9) {
        ratio = 0.9;
      }
      // snap ratio to middle if close
      if (ratio > 0.49 && ratio < 0.51) {
        ratio = 0.5;
      }
      set_lock_split(ratio);
    };
    // element
    lock_divider = <Box sx={SX('divider_border', { width: divider_width })} onMouseDown={divider_mousedown_callback} />;
  }

  // get main view for perusing timeline and measurements
  let main_tab_measurements_view = (
    <TabMeasurementsView
      measurement_data={cleaned_measurement_data}
      mutate_data={mutate_data}
      use_timeline={true}
      lock_views={lock_views}
      set_lock_views={set_lock_views}
      auto_string={auto_string}
      user_id={user_id}
      key={user_id}
    />
  );

  // tabs and content
  let content = (
    <LockViewContext.Provider value={lock_views}>
      <Box sx={SX('flex')} ref={content_box_ref}>
        {lock_view}
        {lock_divider}
        <Box sx={SX({ width: lock_views.length > 0 ? lock_split - divider_width / 2 : 1.0 }, 'flex', 'column')}>
          {lock_views.length > 0 ? <Box sx={{ height: tab_height }} /> : null}
          {main_tab_measurements_view}
        </Box>
      </Box>
    </LockViewContext.Provider>
  );

  return content;
}

// context for lock_views to help color BpTimelineChart
export let LockViewContext = createContext();

UserCard.propTypes = {
  user_id: PropTypes.string.isRequired,
};
