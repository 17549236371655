import React, { useState, useEffect } from 'react';
// mui
import { Box, Button } from '@mui/material';
// other
import ReactJson from 'react-json-view';
// jupyter viewer
import JupyterViewer from 'react-jupyter-notebook';
// zach
import FileUploaderDragDrop from './FileUploaderDragDrop';
import { loading_view } from './utils/generic_utils';
import { SX } from './utils/sx_styling';

// -------------------------------------------------------------------------------------------------------

export default function JupyterCard({ set_expand, set_status_message, set_title_text, busy, set_busy, set_good_card }) {
  // notebook_json: json of notebook uploaded (but not preprocessed/executed on backend)
  let [notebook_json, set_notebook_json] = useState(null);
  // notebook_json_preprocessed: json of notebook uploaded after execution on backend)
  let [notebook_json_preprocessed, set_notebook_json_preprocessed] = useState(null);
  // execution_message: message returned by backend upon completion of execution
  let [execution_message, set_execution_message] = useState(null);
  // successful_execution: bool to indicate if execution of notebook was successful
  let [successful_execution, set_successful_execution] = useState(true);

  // exapnd upon mounting
  useEffect(() => {
    set_default_state();
    set_expand(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function set_default_state() {
    set_title_text('Jupyter Card');
    set_status_message('Upload an ipynb to visualize');
    set_good_card(true);
    set_notebook_json(null);
    set_notebook_json_preprocessed(null);
    set_execution_message(null);
    set_successful_execution(true);
  }

  function handle_upload(file_list) {
    /** handler for file receiver from FileUploaderDragDrop */

    // reject if there is more than 1 file
    if (file_list.length > 1) {
      set_status_message(<Box sx={SX('color_red')}>You may only upload one file</Box>);
      throw new Error('Expected only 1 file');
    }

    // reject if there is not exactly 1 file
    if (file_list.length !== 1) {
      set_status_message(<Box sx={SX('color_red')}>That's not a file</Box>);
      throw new Error(`file_list.length is ${file_list.length}`);
    }

    // get file from array
    let file = file_list[0];

    // only allow .ipynb files
    if (file.name.split('.').slice(-1)[0] !== 'ipynb') {
      set_status_message(
        <Box sx={SX('color_red')}>Uploaded file must be a jupyter notebook (with extension .ipynb)</Box>
      );
      throw new Error('Expected .ipynb file');
    }

    // set executing message
    set_title_text(file.name);
    set_status_message('Executing notebook...');
    set_busy(true);

    // send json...
    file
      .text()
      // get raw text of file to build fetch command
      .then((json_string) => {
        // parse json for state (but not for fetch)
        let notebook_json = JSON.parse(json_string);
        set_notebook_json(notebook_json);
        // send notebook_json to riva-diva-backend for preprocessing
        let url = 'http://127.0.0.1:8000/preprocess_notebook';
        // let headers = {'Content-Type': 'application/json'};
        let headers = { 'Content-Type': 'text/plain' };
        let options = {
          method: 'POST',
          body: json_string,
          headers: headers,
        };
        // issue fetch to backend
        fetch(url, options)
          // upon completion:
          .then((response) =>
            response.json().then((response) => {
              console.log('notebook preprocess complete:', response);
              set_notebook_json_preprocessed(response.preprocessed_notebook);
              set_status_message('Notebook execution complete');
              set_good_card(response.successful_execution);
              set_successful_execution(response.successful_execution);
              set_execution_message(response.execution_message);
            })
          )
          // if an error is encountered:
          .catch((e) => {
            console.log(e);
            set_notebook_json_preprocessed(null);
            set_status_message(`Notebook execution failed --- ${e.toString()}`);
            set_good_card(false);
            set_successful_execution(false);
            set_execution_message(null);
          })
          // regardless set busy to false to turn off animation
          .finally(() => {
            set_busy(false);
          });
      })
      // if the text method fails let the user know
      .catch(() => {
        set_notebook_json_preprocessed(null);
        set_status_message('Error reading file');
        set_good_card(false);
        set_busy(false);
      });
  }

  // return loading view if busy
  if (busy) {
    return loading_view('');
  }

  // get notebook display depending on the state of the original and preprocessed notebooks
  let nb = notebook_json_preprocessed ? notebook_json_preprocessed : notebook_json;
  let notebook_display = <JupyterViewer rawIpynb={nb} />;

  // create a message reflecting the execution, but only show it if the execution was unsuccessful
  let conditional_execution_message = <Box sx={SX('color_red')}>{successful_execution ? null : execution_message}</Box>;

  // make button to clear notebook from card
  let clear_button = (
    <Button
      sx={SX('flex', 'button', 'm3')}
      onClick={() => {
        set_default_state();
      }}
    >
      CLEAR
    </Button>
  );

  // make a component to show the user the json used to generate the notebook render
  let notebook_json_display = <ReactJson src={nb} shouldCollapse={() => ['root']} />;

  // build content
  let content;
  // show the notebook (successful or not) if present in state
  if (notebook_json) {
    content = (
      <Box>
        <Box sx={SX('flex', 'right')}>
          {conditional_execution_message}
          {clear_button}
        </Box>
        <Box>{notebook_display}</Box>
        <br />
        <Box>{notebook_json_display}</Box>
      </Box>
    );
  }
  // if there's no notbook then give the user the element for uploading a notebook
  else {
    content = (
      <Box>
        <FileUploaderDragDrop
          return_files={(file_list) => handle_upload(file_list)}
          // parent={this}
        />
      </Box>
    );
  }

  return content;
}
