import React, { useState, useRef, createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
// mui
import { Box, Button, Collapse } from '@mui/material';
// zach
import ImageWindow from './ImageWindow';
import { riva_loading } from './utils/generic_utils';
import { SX } from './utils/sx_styling';

// -------------------------------------------------------------------------------------------------------

export default function DiagnosticImageView({ measurement_id, diagnostic_images }) {
  /** allows the user to make a selection from the diagnostic images of a bp measurement measurement_id */

  // keep current image as an index (stored in the objects of the diagnostic_images array, not necessarily the array's index)
  let [current_image_idx, set_current_image_idx] = useState(null);
  // state for if diagnostic image is loading
  let [loading_image, set_loading_image] = useState(false);
  // image selection height to have more space
  let [expand_image_scroll_list, set_expand_image_scroll_list] = useState(false);

  // keep track of urls
  let image_urls = useRef([]);
  // keep refs of image buttons to scroll to them
  let image_button_refs = useRef([]);

  // on startup open first image (if present)
  useEffect(() => {
    if (image_button_refs.current.length > 0) {
      load_image(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // account for if there are no images
  if (!diagnostic_images) {
    return <Box sx={SX('flex', 'center', 'sub_section', 'm10')}>{'No Diagnostic Images to show'}</Box>;
  }

  // get indexes

  let image_idxs = diagnostic_images.map((item) => item.idx);

  function iterate_image(up_or_down) {
    /** select subsequent or previous image based on the indexes available from the diagnostic_images */

    // up or down
    let attenuate;
    if (up_or_down === 'up') {
      attenuate = 1;
    } else if (up_or_down === 'down') {
      attenuate = -1;
    } else {
      throw new Error(`unrecognized up_or_down string: ${up_or_down}`);
    }

    // get new index and check that it's valid
    let new_index = image_idxs.indexOf(current_image_idx) + attenuate;
    if (new_index < 0 || new_index >= image_idxs.length) {
      console.warn('image iteration attempted outside of range');
      return;
    }

    // cancel expansion for usability
    set_expand_image_scroll_list(false);

    // load
    load_image(image_idxs[new_index]);
  }

  function load_image(idx) {
    /** load an image with logic based on whether the image promise has resolved or not */

    // scroll function to call after the image is loaded or retrieved
    let scroll = () => {
      image_button_refs.current[idx].current.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
      });
    };

    let image_ob = diagnostic_images.find((item) => item.idx === idx);
    // cancel image selected
    if (current_image_idx === idx) {
      set_current_image_idx(null);
    }
    // use image url if previously loaded
    else if (image_urls.current[idx]) {
      set_current_image_idx(idx);
      scroll();
    }
    // load image url
    else {
      set_loading_image(true);
      set_current_image_idx(null);
      image_ob.image_promise
        .then((stream) => {
          return stream.blob();
        })
        .then((result) => {
          let image_url = URL.createObjectURL(result);
          set_current_image_idx(idx);
          image_urls.current[idx] = image_url;
        })
        .finally(() => {
          set_loading_image(false);
          scroll();
        });
    }
  }

  // create image selection list
  let image_button_list = [];
  for (let image_ob of diagnostic_images) {
    let idx = image_ob.idx;
    let name_string = image_ob.file_name.match('.*__(.*)');
    if (!name_string){ continue }
    name_string = name_string[1];
    let selection_style =
      current_image_idx === idx
        ? ['riva_background_3', { '&:hover': { background: 'rgb(250, 180, 180)' } }]
        : ['hover_color'];
    // make button
    image_button_refs.current[idx] = createRef();
    image_button_list[idx] = (
      <Button
        sx={SX('button', 'm3', { height: '30%' }, ...selection_style)}
        disabled={loading_image}
        key={idx}
        onClick={() => {
          load_image(idx);
        }}
        ref={image_button_refs.current[idx]}
      >
        <Box sx={SX('m3', { p: 0.3, width: 1.0, overflow: 'hidden', textOverflow: 'ellipsis' })}>{name_string}</Box>
      </Button>
    );
  }

  // scroll list of image buttons
  let image_scroll_list = (
    <Box
      sx={SX(
        { height: expand_image_scroll_list ? '80vh' : '20vh', overflow: 'auto' },
        'm3',
        'sub_section',
        'flex',
        'column'
      )}
    >
      {image_button_list}
    </Box>
  );

  // arrow buttons to iterate over images
  let navigation_bar_iterate_image_tools = (
    <Box>
      <Collapse in={!(current_image_idx == null)}>
        <Box sx={SX('flex', 'center', 'm10')}>
          <Button
            sx={SX('button', 'm3')}
            onClick={() => {
              iterate_image('down');
            }}
            disabled={Math.min(...image_idxs) === current_image_idx || current_image_idx == null}
          >
            {'<'}
          </Button>
          <Box sx={SX('grow1', 'flex', 'center', 'center_items')}></Box>
          <Button
            sx={SX('button', 'm3')}
            onClick={() => {
              iterate_image('up');
            }}
            disabled={Math.max(...image_idxs) === current_image_idx || current_image_idx == null}
          >
            {'>'}
          </Button>
        </Box>
      </Collapse>
    </Box>
  );

  // show image
  let current_image = current_image_idx != null ? diagnostic_images.find((item) => item.idx === current_image_idx) : {};
  let image_window = <ImageWindow src={image_urls.current[current_image_idx]} key={current_image.file_name} />;

  // collapsable loading icon for when images take longer than 1s to load
  let image_loading_icon = (
    <Collapse in={loading_image} sx={SX({ transitionDelay: `1000ms` })}>
      <Box sx={SX('flex', 'center', 'm3', { height: '30px' })}>{riva_loading()}</Box>
    </Collapse>
  );

  // return component
  let diagnostic_image_view = (
    <Box sx={SX('flex', 'column', 'sub_section', 'm10')}>
      <Box sx={SX('flex', 'column', 'sub_section', 'm10')}>
        <Box sx={SX('flex', 'center', 'center_items', 'm3', 'column')}>
          {'Diagnostic Images'}
          <Box sx={SX('flex', 'center', { width: 1.0 })}>
            <Button
              sx={SX('button', { width: '0.3' }, 'm3')}
              onClick={() => set_expand_image_scroll_list(!expand_image_scroll_list)}
            >
              {expand_image_scroll_list ? 'CONTRACT' : 'EXPAND'}
            </Button>
          </Box>
        </Box>
        {image_loading_icon}
        {image_scroll_list}
        {navigation_bar_iterate_image_tools}
      </Box>
      <Collapse in={current_image_idx != null}>{image_window}</Collapse>
    </Box>
  );
  return diagnostic_image_view;
}

DiagnosticImageView.propTypes = {
  measurement_id: PropTypes.string.isRequired, // string; id of measurment being shown
};
