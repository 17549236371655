import React, { useState, createContext } from 'react';
import { useParams } from 'react-router-dom';
// mui
import { Box, Collapse, Drawer } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
// zach
import ContentCard from './ContentCard';
import { loading_view } from './utils/generic_utils';
import { ALLOW_IDENTIFYING_INFO } from './utils/settings';
import { SX } from './utils/sx_styling';

// -------------------------------------------------------------------------------------------------------

// context to eventually close auto_string_loading_drawer after navigation is complete
export let NavigatingAutoStringContext = createContext();

export default function BloodPressureDashboard() {
  /** creates a component that contains a single UserGrid and an
   * indefinite number of UserCards. The UserGrid lists all users
   * and allows the user to select them. A UserCard is generated for
   * each user selected
   */

  // params for navigating to measurement (auto_string)
  let params = useParams();

  // users_selected: array to keep track of UserCards currently shown
  let [users_selected, set_users_selected] = useState([]);
  // state to open or close drawer when navigating with auto_string
  let [auto_string_loading_drawer_open, set_auto_string_loading_drawer_open] = useState(!!params.auto_string);
  // show_identifying_info: bool; if true shows identifying info (user name)
  let [show_identifying_info, set_show_identifying_info] = useState(ALLOW_IDENTIFYING_INFO ? true : false);

  function request_user(user_id, user_name = '', auto_string = undefined) {
    /** callback function to add user_id to the list of shown
     * UserCards
     */

    // only add card if the user is not already selected
    let user_ids = users_selected.map((item) => item.user_id);
    if (!user_ids.includes(user_id)) {
      set_users_selected(
        users_selected.concat([
          {
            user_id: user_id,
            user_name: user_name,
            auto_string: auto_string,
          },
        ])
      );
    }
  }

  function close_user_card(user_id) {
    /** close user card by removing it from the list */

    set_users_selected(
      users_selected.filter((value) => {
        return value.user_id !== user_id;
      })
    );
  }

  // create card to list users
  let user_grid = (
    <Box sx={SX('m25')}>
      <ContentCard
        card_type="UserGrid"
        request_user={(user_id, user_name, auto_string) => request_user(user_id, user_name, auto_string)}
        users_selected={users_selected}
        auto_string={params.auto_string}
        show_identifying_info={show_identifying_info}
        set_show_identifying_info={set_show_identifying_info}
      />
    </Box>
  );

  // create cards for users selected from list
  let user_cards = [];
  users_selected.forEach((item, idx) => {
    user_cards.push(
      <Collapse key={item.user_id}>
        <Box sx={SX('m25')}>
          <ContentCard
            card_type="UserCard"
            user_id={item.user_id}
            close_card={() => close_user_card(item.user_id)}
            key={item.user_id}
            auto_string={item.auto_string}
            show_identifying_info={show_identifying_info}
            user_name={item.user_name}
          />
        </Box>
      </Collapse>
    );
  });

  // drawer to disable user interaction with Diva until navigation is complete
  let auto_string_loading_drawer = (
    <Drawer anchor={'bottom'} open={auto_string_loading_drawer_open}>
      <Box sx={SX('flex', 'center', 'center_items', 'column', { height: '20vh' })}>{loading_view('Navigating...')}</Box>
    </Drawer>
  );

  // return
  return (
    <NavigatingAutoStringContext.Provider value={set_auto_string_loading_drawer_open}>
      <Box>
        {user_grid}
        <br />
        <TransitionGroup>{user_cards}</TransitionGroup>
        {auto_string_loading_drawer}
      </Box>
    </NavigatingAutoStringContext.Provider>
  );
}
