import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// mui
import { Box } from '@mui/material';
// zach
import { SX } from './utils/sx_styling';

// -------------------------------------------------------------------------------------------------------

export default function FileUploaderDragDrop({
  return_files, // function to accept dropped file
}) {
  /** component to allow users to upload files by drag-and-drop OR by clicking on the component */

  // references to add event listeners for drag-and-drop
  let dropRef = useRef(null);
  let click_file_input = useRef(null);

  useEffect(() => {
    let enter = (event) => handle_drag_in(event);
    let leave = (event) => handle_drag_out(event);
    let over = (event) => handle_drag(event);
    let drop = (event) => handle_drop(event);
    let drop_box = dropRef.current;

    drop_box.addEventListener('dragenter', enter);
    drop_box.addEventListener('dragleave', leave);
    drop_box.addEventListener('dragover', over);
    drop_box.addEventListener('drop', drop);

    return () => {
      drop_box.removeEventListener('dragenter', enter);
      drop_box.removeEventListener('dragleave', leave);
      drop_box.removeEventListener('dragover', over);
      drop_box.removeEventListener('drop', drop);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // componentDidMount() {
  //   /** listen for drag-and-drops */

  //   let drop_box = dropRef.current;
  //   drop_box.addEventListener('dragenter', (event) => this.handle_drag_in(event));
  //   drop_box.addEventListener('dragleave', (event) => this.handle_drag_out(event));
  //   drop_box.addEventListener('dragover', (event) => this.handle_drag(event));
  //   drop_box.addEventListener('drop', (event) => this.handle_drop(event));
  // }

  // componentWillUnmount() {
  //   /** stop listening for drag-and-drops */

  //   let drop_box = this.dropRef.current;
  //   drop_box.removeEventListener('dragenter', (event) => this.handle_drag_in(event));
  //   drop_box.removeEventListener('dragleave', (event) => this.handle_drag_out(event));
  //   drop_box.removeEventListener('dragover', (event) => this.handle_drag(event));
  //   drop_box.removeEventListener('drop', (event) => this.handle_drop(event));
  // }

  function handle_drag_in(event) {
    /** account for drag in */

    event.preventDefault();
    event.stopPropagation();
    // console.log('in');
  }

  function handle_drag_out(event) {
    /** account for drag out */

    event.preventDefault();
    event.stopPropagation();
    // console.log('out');
  }

  function handle_drag(event) {
    /** account for drag over */

    event.preventDefault();
    event.stopPropagation();
    // console.log('drag!');
  }

  function handle_drop(event) {
    /** handle dropped file */

    event.preventDefault();
    event.stopPropagation();
    return_files(event.dataTransfer.files);
  }

  function click_file_uploader() {
    /** handle click on element for file selection
     * a click event is created and sent to the invisible
     * <input> element rendered below
     */

    let event = new MouseEvent('click', {
      view: window,
      bubbles: false,
      cancelable: true,
    });
    click_file_input.current.dispatchEvent(event);
  }

  // create invisible input to be triggered by clicking on component
  let invisible_input = (
    <input
      id="file-input"
      type="file"
      ref={click_file_input}
      style={{ display: 'none' }}
      onChange={() => return_files(click_file_input.current.files)}
      accept=".ipynb"
    />
  );

  // return component
  return (
    <Box
      sx={SX('flex', 'center', 'width100', 'pointer', 'hover_color')}
      ref={dropRef}
      onClick={() => click_file_uploader()}
    >
      <Box component="img" src="/static/upload_platform_drawing.svg" sx={SX('height400px')} />
      {invisible_input}
    </Box>
  );
}

FileUploaderDragDrop.propTypes = {
  return_files: PropTypes.func.isRequired, // func; function to accept dropped file
};
