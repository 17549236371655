import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// icons
import { Icon } from '@iconify/react';
// mui
import { Button, Box, Slider } from '@mui/material';
// zach
import { download_json_button, get_date_string } from './utils/generic_utils';
import BpTimelineChart from './BpTimelineChart';
import TagsFilterDrawer from './TagsFilterDrawer';
import { SX } from './utils/sx_styling';
// styles
import './styles/BpTimelineStyle.css';

// -------------------------------------------------------------------------------------------------------

export default function BpTimeline({ measurement_data, add_measurement_tab }) {
  /** BpTimeline takes measurement_data as properties and displays a timeline of bp sets */

  // get min_max_date; the date range from measurement data
  let dates = measurement_data.map((item) => item.created.at.date_object);
  let min_max_date = [new Date(Math.min(...dates)), new Date(Math.max(...dates))];
  let past_24_hours_dates = [new Date(Math.max(min_max_date[1] - 86400000, min_max_date[0])), min_max_date[1]];

  // start_end_date: dates selected by the user to filter timeline
  let [start_end_date, set_start_end_date] = useState(past_24_hours_dates);
  // max_start: bool; state to keep track if slider date filter includes maximum date
  let [max_start, set_max_start] = useState(true);
  // view_type: string; controls type of bpTimelineChart (timeline, enumerate, line)
  let [view_type, set_view_type] = useState('timeline');
  // tags_filter: array; lists all tags to filter by, no filtering if null
  let [tags_filter_array, set_tags_filter_array] = useState(null);
  // controls if TagsFilterDrawer is open or closed
  let [tags_filter_drawer_open, set_tags_filter_drawer_open] = useState(false);

  // update start date of start_end_date to be the latest date when measurement_data changes,
  // but only if max_start is already true
  useEffect(() => {
    if (max_start) {
      let dates = measurement_data.map((item) => item.created.at.date_object);
      set_start_end_date([start_end_date[0], new Date(Math.max(...dates))]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measurement_data]);

  // update max_start when start_end_date changes
  useEffect(() => {
    let date_numbers = measurement_data.map((item) => item.created.at.date_object.getTime());
    set_max_start(date_numbers.includes(start_end_date[1].getTime()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_end_date]);

  function generate_bp_timeline() {
    /** fitler measurement_data and use it to generate a BpTimelineChart */

    // copy measurement_data
    let filtered_measurement_data = measurement_data.filter((item) => true);

    // filter dates based on start_end_date
    let start_time = +start_end_date[0];
    let end_time = +start_end_date[1];
    filtered_measurement_data = filtered_measurement_data.filter((meas) => {
      let meas_time = +meas.created.at.date_object;
      return meas_time >= start_time && meas_time <= end_time;
    });

    // filter tags
    if (tags_filter_array) {
      filtered_measurement_data = filtered_measurement_data.filter((meas) => {
        // loop through tags_filter_array and figure out if the meas has at least one of these keys with a true value
        return (
          Object.keys(meas.tags)
            .map((tag_key) => meas.tags[tag_key] === true && tags_filter_array.includes(tag_key))
            .find((item) => item === true) !== undefined
        );
      });
    }

    // generate chart
    let bp_timeline_chart = (
      <BpTimelineChart
        measurement_data={filtered_measurement_data}
        add_measurement_tab={add_measurement_tab}
        view_type={view_type}
      />
    );

    return bp_timeline_chart;
  }

  function generate_date_pickers() {
    /** generates two datepicking widgets to alow the user to specify a date
     * range of measurements shown by the timeline. Also includes an "All Dates"
     * button.
     */

    // button to show last 24 hours
    let last_24_hours_button = (
      <Button sx={SX('button', 'm3')} onClick={() => set_start_end_date(past_24_hours_dates)}>
        {'SET[-1] -'}
        <br />
        {'24HRS'}
      </Button>
    );
    // button to show all dates
    let show_all_button = (
      <Button sx={SX('button', 'm3')} onClick={() => set_start_end_date(min_max_date)}>
        {'ALL'}
        <br />
        {'DATES'}
      </Button>
    );

    // date range labels
    let before_date_label = (
      <Box sx={SX('inline_flex', 'center', 'grow1', 'm3')}>{get_date_string(start_end_date[0])}</Box>
    );
    let after_date_label = (
      <Box sx={SX('inline_flex', 'center', 'grow1', 'm3')}>{get_date_string(start_end_date[1])}</Box>
    );

    // date range slider
    let value = start_end_date.map((date) => {
      return date_to_value(date);
    });
    let date_slider = (
      <Slider
        value={value}
        onChange={(event, value) => date_slider_change(event, value)}
        // onChangeCommitted={(event, value) => date_slider_change(event, value)}
        disableSwap
      />
    );

    // return all dates button and datepickers
    return (
      <Box sx={SX('flex', 'sub_section', 'm10')}>
        {last_24_hours_button}
        {show_all_button}
        <Box sx={SX('flex', 'column', 'grow1')}>
          {/* <Box sx={SX('flex')}>{before_date_picker}{after_date_picker}</Box> */}
          <Box sx={SX('flex')} className={'no_select'}>
            {before_date_label}
            {after_date_label}
          </Box>
          <Box sx={SX('flex', 'date_slider_margins')}>{date_slider}</Box>
        </Box>
      </Box>
    );
  }

  function date_slider_change(event, value) {
    /** take value, convert to date, update date range */

    let dates = value.map((val) => {
      return value_to_date(val);
    });
    set_start_end_date(dates);
  }

  function date_to_value(date_ob) {
    /** take date and return value from 0-100 relative to the min_max_date range */

    let value = ((+date_ob - +min_max_date[0]) / (+min_max_date[1] - +min_max_date[0])) * 100;
    if (value < 0 || value > 100) {
      console.warn(`date_ob ${date_ob} is outside of min_max_date range`);
      if (value < 0) {
        value = 0;
      }
      if (value > 100) {
        value = 100;
      }
    }
    return value;
  }

  function value_to_date(value) {
    /** take value from 0-100 and return a date based on the min_max_date range */

    if (value < 0 || value > 100) {
      console.warn(`value ${value} should be in the range [0, 100]`);
      if (value < 0) {
        value = 0;
      }
      if (value > 100) {
        value = 100;
      }
    }
    let num = (value / 100) * (+min_max_date[1] - +min_max_date[0]) + +min_max_date[0];
    return new Date(num);
  }

  // get timeline and datepickers only if there is measurement_data
  let bp_timeline = null;
  let date_pickers = null;
  if (measurement_data) {
    bp_timeline = generate_bp_timeline();
    date_pickers = generate_date_pickers();
  }

  // toggle buttons
  let toggle_buttons = [];
  let view_type_info = [
    { name: 'timeline', icon: 'fa6-solid:timeline' },
    { name: 'enumerate', icon: 'clarity:timeline-line' },
    { name: 'line', icon: 'ic:baseline-timeline' },
  ];
  view_type_info.forEach((info, idx) => {
    toggle_buttons.push(
      <Button
        sx={SX(view_type === info.name ? 'toggle_on_button' : 'toggle_off_button', 'm3', 'width10', 'height100pct')}
        onClick={() => set_view_type(info.name)}
        key={idx}
      >
        <Box component={Icon} icon={info.icon} sx={SX('color_black', { width: '80%', height: '80%' })} />
      </Button>
    );
  });

  // toggle button to filter tags
  let tags_filter_button = (
    <Button
      sx={SX(
        Array.isArray(tags_filter_array) ? 'toggle_on_button' : 'toggle_off_button',
        'm3',
        'width20',
        'height100pct'
      )}
      onClick={() => {
        set_tags_filter_drawer_open(true);
      }}
    >
      <Box component={Icon} icon={'akar-icons:tag'} sx={SX('color_black', { width: '10%', height: '80%' }, 'm10')} />
      FILTER TAGS
    </Button>
  );

  // buttons to change chart type and filtering
  let tweak_chart_buttons = (
    <Box sx={SX('flex', { justifyContent: 'space-evenly', height: '7vh' })}>
      <Box>{toggle_buttons}</Box>
      {tags_filter_button}
    </Box>
  );

  // box to hold filtering and modification tools for timeline
  let timeine_filter_modify_box = (
    <Box sx={SX('flex', 'column', 'grow1', { width: 0.8 })}>
      <Box sx={SX('sub_section', 'm10', { p: '4px' })}>{bp_timeline}</Box>
      {tweak_chart_buttons}
      {date_pickers}
    </Box>
  );

  // get user_id from measurment_data
  let user_id;
  if (measurement_data.length !== 0) {
    user_id = measurement_data[0].userId;
  } else {
    console.warn('measurement_data has length of zero; this is an unexpected occurance! Unable to parse user_id');
  }

  // download json buttons
  let measurement_download_button = download_json_button(
    measurement_data,
    `measurement_data__${user_id}`,
    'DOWNLOAD measurement_data',
    ['button', 'm3']
  );

  // drawer to specify tags to filter
  let tags_filter_drawer = (
    <TagsFilterDrawer
      tags_filter_drawer_open={tags_filter_drawer_open}
      set_tags_filter_drawer_open={set_tags_filter_drawer_open}
      measurement_data={measurement_data}
      tags_filter_array={tags_filter_array}
      set_tags_filter_array={set_tags_filter_array}
    />
  );

  // return BpTimeline
  return (
    <Box sx={SX('flex', 'column')}>
      <Box sx={SX('flex')}>{timeine_filter_modify_box}</Box>
      {measurement_download_button}
      {tags_filter_drawer}
    </Box>
  );
}

BpTimeline.propTypes = {
  measurement_data: PropTypes.array.isRequired, // array of objects; one for each measurement
  add_measurement_tab: PropTypes.func.isRequired, // callback for when a new tab is created
};
