import React, { useContext } from 'react';
// mui
import { Box, Button } from '@mui/material';
// icons
import { Icon } from '@iconify/react';
// zach
import { SnackMessageContext } from '../App';
import { SX } from './utils/sx_styling';

// -------------------------------------------------------------------------------------------------------

export default function ShareDivaLinkBox({ individual_id, measurement_id }) {
  /** element to provide an easily copied sharable link to a Diva user and/or measurement (copy button included in element) */

  // snack message to indicate something was copied
  let display_snackbar_message = useContext(SnackMessageContext);

  // box to copy and share this measurement
  let domain = window.location.href.match('(.*)' + window.location.pathname)[1];
  let link = domain + `/dashboard/${individual_id}${measurement_id ? '__' + measurement_id : ''}`;
  let share_link = (
    <Box sx={SX('sub_section', 'm10', 'flex')}>
      <Button
        onClick={() => {
          navigator.clipboard.writeText(link);
          display_snackbar_message('Link copied');
        }}
      >
        <Box component={Icon} icon={'akar-icons:copy'} sx={SX('color_black', { width: '80%', height: '80%' })} />
      </Button>
      <Box sx={SX('m10', { overflow: 'auto', whiteSpace: 'nowrap' })}>{link}</Box>
    </Box>
  );

  return share_link;
}
